import React from "react";
import LotTypeSelector from "../Common/LotTypeSelector";
import LocationSelector from "../Common/LocationSelector";
import ShippingToggle from "../Common/ShippingToggle";
import CategorySelector from "../CategorySelector";

const LotsSideBar = ({ lotSidebarState, updateState }) => {
  return (
    <div className="lots-sidebar">
      <div className="sidebar auction-sidebar">
        {/* <div className='sidebar-item text-start justify-between font-bold'
                    onClick={() => updateState('showSearchOptions', !lotSidebarState.showSearchOptions)}>
                    QUICK SEARCH
                    <div className='mx-2'>
                        <FontAwesomeIcon icon={lotSidebarState.showSearchOptions ? faAngleUp : faAngleDown} className='toggle-icon quick-search' />
                    </div>
                </div>

                {lotSidebarState.showSearchOptions && (
                    <>
                        <div className='form-group text-start d-flex' style={{ flexDirection: "row" }}>
                            <input type='text' className='form-control search-the-event px-2' placeholder='Search...' style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }} />
                            <div className='faSearch'>
                                <button className='btn btn-outline-secondary search-event-lots' type='button' style={{ border: "1px solid #2695FF" }} >
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        </div>
                        <div className='sidebar-sub-item'>
                            <div className='form-group d-flex gap-2'>
                                <input
                                    type='text'
                                    className='form-control search-the-event px-2'
                                    placeholder='Enter ZIP code'
                                />
                                <div className='dropdown-container'>
                                    <select className='custom-select'>
                                        <option>50 miles</option>
                                        <option>100 miles</option>
                                        <option>200 miles</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>

                )} */}
        <LotTypeSelector
          selectedLotStatus={lotSidebarState.selectedLotStatus}
          handleLotStatusChange={(status) =>
            updateState("selectedLotStatus", status)
          }
        />
        {/* <AuctionStatusSelector
                    selectedAuctionStatus={lotSidebarState.selectedAuctionStatus}
                    handleAuctionStatusChange={(status) => updateState('selectedAuctionStatus', status)}
                /> */}
        <ShippingToggle
          shippingAvailable={lotSidebarState.shippingAvailable}
          setShippingAvailable={(value) =>
            updateState("shippingAvailable", value)
          }
        />
        <LocationSelector
          handleCountryChange={(value) => updateState("selectedCountry", value)}
          handleCountryStateChange={(value) =>
            updateState("selectedCountryState", value)
          }
        />
        <CategorySelector
          handleCategoryChange={(value) =>
            updateState("selectedCategory", value)
          }
        />
      </div>
    </div>
  );
};

export default LotsSideBar;
