import React from 'react';
import "./Home.css";
import Category from './Category';
import Picks from './Picks';
import Auctions from './Auctions';
import Search from './Search';
import { Helmet } from 'react-helmet';

const Home = () => {
const url = window.location.href;

    return (
        <>
            <Helmet>                
                <meta
                    name="description"
                    content="Experience live online bidding on a wide range of items at AuctionsLive.net. Join thousands of bidders for exciting auctions, from collectibles to real estate. Start bidding today!"
                />
                <meta
                    name="keywords"
                    content="auction help, online bidding, bidding strategies, auction tips, Auctionslive, how to bid, auction guide, online auctions, bid successfully"
                />
                <meta property="og:title" content="Bidding | Live Online Auctions" />
                <meta property="og:description" content="Experience live online bidding on a wide range of items at AuctionsLive.net. Join thousands of bidders for exciting auctions, from collectibles to real estate. Start bidding today!" />
                <meta property="og:url" content={url} />
                <link rel="canonical" href={url} />

            </Helmet>
            <div className='home'>
                <Search />
                <Category />
                <Picks />
                <Auctions />
            </div>
        </>
    )
}

export default Home;
