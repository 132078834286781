import React, { useEffect, useState } from "react";
import "./Slider.css";
import axios from "axios";
import { Link } from "react-router-dom";



const Slider = () => {
  const [auctions, setAuctions] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);


  const fetchAuctions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/latest/auctions`,
        { headers: { "ngrok-skip-browser-warning": "69420" } }
      );
      // console.log(response.data.data);
      setAuctions(response.data.data);
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  };

  useEffect(() => {
    fetchAuctions();
  }, []);


  const previousSlide = () => {
    const newIndex =
      currentSlide === 0 ? auctions.length - 1 : currentSlide - 1;
    setCurrentSlide(newIndex);
  };

  const nextSlide = () => {
    const newIndex =
      currentSlide === auctions.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(newIndex);
  };

  return (
    <div className="slider-container-slider">
      <div
        className="slider-wrapper"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {auctions.map((slide, index) => (
          <div key={index} className="slider-card slider-card-mobile">
            <div className="slider-image-container slider-image-container-mobile">
              <Link to={`/auctions/${slide.hash_id}`} className="text-decoration-none">
                <img
                  src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${slide.auction_images[0].image_path}`}
                  alt={slide.text}
                  className="slider-image"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="slider-text-container slider-text-container-mobile">
              <Link to={`/auctions/${slide.hash_id}`} className="text-decoration-none text-black">
                <h3 className="slider-text slider-text-mobile">{slide.name}</h3>
              </Link>
              <p className="slider-desc">{slide.description}</p>
              <p className="slider-heading mb-0">Lots Available: {slide.lots.length}</p>
              <p className="slider-heading">End Date: {new Date(slide.end_date).toLocaleDateString()}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="slider-controls-slider">
        <button className="slider-control prev" onClick={previousSlide}>
          <i
            className="fa-solid fa-arrow-left"
            style={{ color: "#2695FF" }}
          ></i>
        </button>
        <button className="slider-control next" onClick={nextSlide}>
          <i
            className="fa-solid fa-arrow-right"
            style={{ color: "#2695FF" }}
          ></i>
        </button>
      </div>
    </div>
  );
};

export default Slider;
