import React from "react";
import PropTypes from "prop-types";
import { Box, Modal } from "@mui/material";
import AuctionForm from "./AuctionForm";
import "./AddAuctionModal.css";

const AddAuctionModal = ({
  open,
  onClose,
  handleChange,
  handleSave,
  newAuctionData,
  fetchAuctions,
}) => {
  return (
    <Modal open={open} close={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <AuctionForm
          onClose={onClose}
          handleChange={handleChange}
          handleSave={handleSave}
          newAuctionData={newAuctionData}
          fetchAuctions={fetchAuctions}
        />
      </Box>
    </Modal>
  );
};

AddAuctionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddAuctionModal;
