import React from 'react'
import { Navigate } from 'react-router-dom';

function ProtectedRoute({children}) {
    const accessToken = localStorage.getItem('access_token');

    if (!accessToken) {
      return <Navigate to="/authentication/sign-in" replace />;
    }
  
    // If the user is authenticated, allow access to the page
    return children;
  };

export default ProtectedRoute