import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import axios from "axios";
import "./Table.css";

const formatTimeRemaining = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const difference = end - now;
  if (difference <= 0) return "Completed";
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  let result = "";
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0 || days > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || hours > 0 || days > 0) {
    result += `${minutes}m `;
  }
  result += `${seconds}s`;
  return result.trim();
};

function Tables() {
  const { id } = useParams();
  const [view, setView] = useState("detail");
  const [auction, setAuction] = useState(null);
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleRowClick = (hash_id) => {
    navigate(`/auction-lots-detail/${hash_id}`);
  };

  const [columnVisibility, setColumnVisibility] = useState({
    lotNumber: true,
    title: true,
    timeLeft: true,
    views: true,
  });

  const fetchAuctions = useCallback(async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setError("Access token not found.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get/auctions/${id}`,
        { headers }
      );
      if (response.data.auction) {
        setAuction(response.data.auction);
        // console.log(response.data.auction, "tables")
      } else {
        setError("No auction found.");
      }
    } catch (error) {
      setError("Error fetching auctions.");
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchAuctions();
    }
  }, [id, fetchAuctions]);

  const handleSearchChange = useCallback((event) => {
    setSearch(event.target.value);
  }, []);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleColumnVisibilityChange = (event) => {
    setColumnVisibility({
      ...columnVisibility,
      [event.target.name]: event.target.checked,
    });
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="container">
      <div className="row">
        {/* <div className="flex items-center justify-start pt-3 pb-1" >
          <button onClick={handleGoBack} className="px-3 py-2 cursor-pointer bg-red-400 rounded-lg gap-2 flex items-center justify-center text-white font- text-lg">
          <i class="fa-solid fa-arrow-left"></i>Go Back</button>
          </div> */}
        <Box pt={1} pb={2}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <Box
                  mx={2}
                  py={3}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Typography className="tables-title-auctioner">
                    Auction Detail
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={(event, newView) => {
                    if (newView !== null) {
                      setView(newView);
                    }
                  }}
                  aria-label="View Toggle"
                  sx={{ mb: 2 }}
                >
                  <ToggleButton value="detail">Basic Info</ToggleButton>
                  <ToggleButton value="registration">Detail</ToggleButton>
                  <ToggleButton value="lotsStats"> Auction lots </ToggleButton>
                </ToggleButtonGroup>
                {error && <Typography color="error">{error}</Typography>}
                {view === "detail" && auction && (
                  <Box
                    sx={{ mt: 1, border: 1, p: 2, bgcolor: "background.paper" }}
                    className="border rounded-lg"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <Typography
                            variant="h4"
                            className="text-black font-extrabold "
                          >
                            Auction Information :-
                          </Typography>
                          <div className="mt-3" style={{ marginTop: "20px" }}>
                            <Typography variant="body1">
                              <b id="date-location"> Name: </b> &nbsp;
                              {auction.name}
                            </Typography>
                            <Typography variant="body1">
                              <b id="date-location"> Description: </b> &nbsp;
                              {auction.description}
                            </Typography>
                            {auction.terms_and_conditions && (
                              <Typography variant="body1">
                                <b id="date-location">
                                  {" "}
                                  Terms and Conditions:{" "}
                                </b>
                                &nbsp;{auction.terms_and_conditions}
                              </Typography>
                            )}
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <Typography
                            variant="h4"
                            className="text-black font-extrabold "
                          >
                            Dates and Location :-
                          </Typography>
                          <div style={{ marginTop: "20px" }}>
                            <Typography variant="body1">
                              <b id="date-location"> Start Date:</b> &nbsp;
                              {auction.start_date}
                            </Typography>
                            <Typography variant="body1">
                              {" "}
                              <b id="date-location">City:</b> &nbsp;
                              {auction.city}
                            </Typography>
                            <Typography variant="body1">
                              {" "}
                              <b id="date-location">State: </b> &nbsp;
                              {auction.state.name}
                            </Typography>
                            <Typography variant="body1">
                              {" "}
                              <b id="date-location">Zip Code:</b> &nbsp;
                              {auction.zip_code}
                            </Typography>
                            <Typography variant="body1">
                              {" "}
                              <b id="date-location">Country:</b> &nbsp;{" "}
                              {auction.country.name}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {view === "registration" && (
                  <Box sx={{ mt: 1, p: 2, bgcolor: "background.paper" }}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableRow>
                          <TableCell>
                            <b>Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Country</b>
                          </TableCell>
                          <TableCell>
                            <b>State</b>
                          </TableCell>
                          <TableCell>
                            <b>City</b>
                          </TableCell>
                          <TableCell>
                            <b>Location</b>
                          </TableCell>
                          <TableCell>
                            <b>Start Date</b>
                          </TableCell>
                          <TableCell>
                            <b>End Date</b>
                          </TableCell>
                          <TableCell>
                            <b>Min Amount</b>
                          </TableCell>
                          <TableCell>
                            <b>Max Amount</b>
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow>
                            <TableCell>{auction.name}</TableCell>
                            <TableCell>{auction.country?.name}</TableCell>
                            <TableCell>{auction.state?.name}</TableCell>
                            <TableCell>{auction.city}</TableCell>
                            <TableCell>{auction.location}</TableCell>
                            <TableCell>{auction.start_date}</TableCell>
                            <TableCell>{auction.end_date}</TableCell>
                            <TableCell>
                              {auction.bid_increments[0]?.min_amount}
                            </TableCell>
                            <TableCell>
                              {auction.bid_increments[0]?.max_amount}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                {view === "lotsStats" && auction && (
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <TextField
                        label="Search"
                        variant="outlined"
                        value={search}
                        onChange={handleSearchChange}
                        sx={{ flex: 1 }}
                        id="lots-table-data"
                      />
                      <IconButton color="primary" id="search-icon">
                        <SearchIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={handleDialogOpen}>
                        <SettingsIcon />
                      </IconButton>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table>
                        {columnVisibility.lotNumber && (
                          <TableCell className="text-nowrap text-center">
                            {" "}
                            <b>Images</b>
                          </TableCell>
                        )}
                        {columnVisibility.lotNumber && (
                          <TableCell className="text-nowrap text-center">
                            <b>Lot Number</b>
                          </TableCell>
                        )}
                        {columnVisibility.title && (
                          <TableCell className="text-nowrap text-center">
                            <b>Title</b>
                          </TableCell>
                        )}
                        {columnVisibility.timeLeft && (
                          <TableCell className="text-nowrap text-center">
                            <b>Time Left</b>
                          </TableCell>
                        )}
                        {columnVisibility.views && (
                          <TableCell className="text-nowrap text-center">
                            <b>Total Bids</b>
                          </TableCell>
                        )}
                        <TableBody>
                          {auction.lots
                            .filter((lot) =>
                              lot.title
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            )
                            .map((lot) => (
                              <TableRow
                                key={lot.hash_id}
                                onClick={() => handleRowClick(lot.hash_id)}
                                style={{ cursor: "pointer" }}
                              >
                                {columnVisibility.lotNumber && (
                                  <TableCell className="text-center self-center flex items-center justify-center hi">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_IMAGE_URL}${auction?.auction_images[0]?.image_path}`}
                                      alt="Auction"
                                      style={{
                                        maxWidth: "50%",
                                        height: "auto",
                                      }}
                                      loading="lazy"
                                    />
                                  </TableCell>
                                )}
                                {columnVisibility.lotNumber && (
                                  <TableCell className="text-center self-center">
                                    {lot.lot_number}
                                  </TableCell>
                                )}
                                {columnVisibility.title && (
                                  <TableCell className="text-center self-center">
                                    {lot.title}
                                  </TableCell>
                                )}
                                {columnVisibility.timeLeft && (
                                  <TableCell className="text-center self-center">
                                    {auction.start_date
                                      ? formatTimeRemaining(auction.start_date)
                                      : "No Date"}
                                  </TableCell>
                                )}
                                {columnVisibility.views && (
                                  <TableCell className="text-center self-center">
                                    {lot.bids && Array.isArray(lot.bids)
                                      ? lot.bids.length
                                      : 0}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Column Visibility</DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility.lotNumber}
                  onChange={handleColumnVisibilityChange}
                  name="lotNumber"
                />
              }
              label="Lot Number"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility.title}
                  onChange={handleColumnVisibilityChange}
                  name="title"
                />
              }
              label="Title"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility.timeLeft}
                  onChange={handleColumnVisibilityChange}
                  name="timeLeft"
                />
              }
              label="Time Left"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility.views}
                  onChange={handleColumnVisibilityChange}
                  name="views"
                />
              }
              label="Views"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default Tables;
