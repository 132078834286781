import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditModal = ({
  show,
  onHide,
  config,
  newValue,
  onNewValueChange,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState(newValue);
  const isDefaultCurrency = config?.key === "default_currency";
  const isPercentageCharge = config?.key === "percentage_charge";
  const isNumericKey = [
    "min_bid_increment",
    "max_bid_limit",
    "reserve_price",
  ].includes(config?.key);
  const isDurationKey = config?.key === "default_auction_duration";

  useEffect(() => {
    setInputValue(newValue);
  }, [newValue]);

  const handleValueChange = (e) => {
    let value = e.target.value;
    const cleanedValue = value.replace(/[^0-9.]/g, "");

    if (isPercentageCharge) {
      const numericValue = parseFloat(cleanedValue);
      if ((numericValue >= 0 && numericValue <= 100) || cleanedValue === "") {
        setInputValue(cleanedValue);
        onNewValueChange(cleanedValue);
      }
    }
    // Handle numeric input (for min_bid_increment, max_bid_limit, reserve_price, auction duration)
    else if (isNumericKey || isDurationKey) {
      const numericValue = Number(cleanedValue);
      if (numericValue >= 0 || cleanedValue === "") {
        setInputValue(cleanedValue);
        onNewValueChange(cleanedValue);
      }
    }
    // General input handling for non-numeric fields
    else {
      setInputValue(cleanedValue);
      onNewValueChange(cleanedValue);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Key</Form.Label>
            <Form.Control type="text" value={config?.key || ""} readOnly />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Value</Form.Label>
            <Form.Control
              type={
                isNumericKey || isDurationKey || isPercentageCharge
                  ? "number"
                  : "text"
              }
              value={inputValue}
              onChange={handleValueChange}
              readOnly={isDefaultCurrency}
              placeholder={isDefaultCurrency ? "" : "Enter value"}
              min={0}
            />
            {isPercentageCharge && (
              <Form.Text className="text-muted">
                Value should be between 0% and 100%.
              </Form.Text>
            )}
            {isNumericKey && (
              <Form.Text className="text-muted">
                Enter a numeric value.
              </Form.Text>
            )}
            {isDurationKey && (
              <Form.Text className="text-muted">
                Enter a valid auction duration.
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
