import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import './Adminsubscribe.css';
import { format } from 'date-fns';


function Adminsubscribe() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        { name: 'id', label: 'ID' },
        { name: 'email', label: 'Email' },
        { name: 'created_at', label: 'Subscribed At', options: { sort: true } },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.auctionslive.net/api/subscribers');
                // console.log(response.data.subscribers);
                const formattedData = response.data.subscribers.map(item => ({
                    ...item,
                    created_at: format(new Date(item.created_at), 'MMMM dd, yyyy, h:mm a') 
                }));
                setData(formattedData || []); 
            } catch (error) {
                console.error('Error fetching subscribers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        download: false,
        print: false,
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <section className='admin-subscribe'>
            <div className='container'>
                <div className='row mt-5 px-3'>
                    <h2>ALL SUBSCRIBERS</h2>
                    <MUIDataTable
                        data={data || []}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </section>
    );
}

export default Adminsubscribe;
