import React, { useEffect, useState } from "react";
import {
  Modal,
  TextField,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const EditAuctionModal = ({ open, close, editData, fetchAuctions }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [lots, setLots] = useState(editData?.lots || []);
  const [lotCount, setLotCount] = useState(1);
  const [bidIncrements, setBidIncrements] = useState(
    editData?.bid_increments || []
  );
  const [loadingStates, setLoadingStates] = useState(false);
  const [bidding, setBidding] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);

  // console.log(editData, "hash ggid");

  useEffect(() => {
    if (
      editData &&
      editData.auction_images &&
      editData.auction_images.length > 0
    ) {
      const images = editData.auction_images.map(
        (img) => `${process.env.REACT_APP_BASE_IMAGE_URL}/${img.image_path}`
      );
      setCurrentImage(images);
    }
  }, [editData]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/countries`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const { data } = response.data;
        setCountries(data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  // Fetch states for the selected country
  const fetchStates = async (countryId) => {
    setLoadingStates(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;
      setStates(data);
    } catch (error) {
      console.error("Error fetching states:", error);
    } finally {
      setLoadingStates(false);
    }
  };

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    bidding_type_id: Yup.string().required("bidding_type_id is required"),
    lots: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Title is required"),
        category_id: Yup.string().required("category_id is required"),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editData?.name || "",
      auctioneer: editData?.auctioneer || "",
      start_date: editData?.start_date || "",
      end_date: editData?.end_date || "",
      auction_date_time_info: editData?.auction_date_time_info || "",
      preview_start_datetime: editData?.preview_start_datetime || "",
      checkout_date_time: editData?.checkout_date_time || "",
      location: editData?.location || "",
      buyer_premium: editData?.buyer_premium || "",
      description: editData?.description || "",
      deposit_amount: editData?.deposit_amount || "",
      checkout_start_datetime: editData?.checkout_start_datetime || "",
      checkout_end_datetime: editData?.checkout_end_datetime || "",
      country: editData?.country?.name || "",
      state: editData?.state?.name || "",
      city: editData?.city || "",
      zip_code: editData?.zip_code || "",
      terms_and_conditions: editData?.terms_and_conditions || "",
      bidding_notice: editData?.bidding_notice || "",
      status: editData?.status || "",
      card_verified_required: editData?.card_verified_required || false,
      featured: editData?.featured || false,
      is_shipping_available: editData?.is_shipping_available || 0,
      pickup_info: editData?.pickup_info || "",
      bidding_type_id: editData?.bidding_type_id || "",
      meta_title: editData?.meta_title || "",
      meta_description: editData?.meta_description || "",
      auction_images: [],
      lots: editData?.lots || [
        {
          title: "",
          description: "",
          images: "",
          category_id: "",
          estimate_from: "",
          estimate_to: "",
          lot_meta_title: "",
          lot_meta_description: "",
        },
      ],
    },
    validationSchema,
    onSubmit: async (values) => {
      // console.log(values, "form values");
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("auctioneer", values.auctioneer);
        formData.append("start_date", values.start_date);
        formData.append("end_date", values.end_date);
        formData.append(
          "auction_date_time_info",
          values.auction_date_time_info
        );
        formData.append(
          "preview_start_datetime",
          values.preview_start_datetime
        );
        formData.append("checkout_date_time", values.checkout_date_time);
        formData.append("location", values.location);
        formData.append("buyer_premium", values.buyer_premium);
        formData.append("description", values.description);
        formData.append("deposit_amount", values.deposit_amount);
        formData.append(
          "checkout_start_datetime",
          values.checkout_start_datetime
        );
        formData.append("checkout_end_datetime", values.checkout_end_datetime);
        formData.append("country", values.country);
        formData.append("state", values.state);
        formData.append("city", values.city);
        formData.append("zip_code", values.zip_code);
        formData.append("terms_and_conditions", values.terms_and_conditions);
        formData.append("bidding_notice", values.bidding_notice);
        formData.append("status", values.status);
        formData.append(
          "is_shipping_available",
          values.is_shipping_available ? 1 : 0
        );
        formData.append("pickup_info", values.pickup_info);
        formData.append("bidding_type_id", values.bidding_type_id);
        formData.append(
          "card_verified_required",
          values.card_verified_required ? 1 : 0
        );
        formData.append("featured", values.featured ? 1 : 0);
        formData.append("meta_title", values.meta_title);
        formData.append("meta_description", values.meta_description);

        values.lots.forEach((lot, index) => {
          formData.append(`lots[${index}][title]`, lot.title || "");
          formData.append(`lots[${index}][description]`, lot.description || "");
          formData.append(`lots[${index}][category_id]`, lot.category_id || "");
          formData.append(`lots[${index}][estimate_from]`, lot.estimate_from);
          formData.append(`lots[${index}][estimate_to]`, lot.estimate_to);
          formData.append(`lots[${index}][lot_meta_title]`, lot.lot_meta_title);
          formData.append(
            `lots[${index}][lot_meta_description]`,
            lot.lot_meta_description
          );
          if (
            lot.images &&
            Array.isArray(lot.images) &&
            lot.images.length > 0
          ) {
            lot.images.forEach((image) => {
              if (image instanceof Blob) {
                formData.append(`lots[${index}][images]`, image);
              }
            });
          }
          // if (lot.lot_number) {
          //   formData.append(`lots[${index}][lot_number]`, lot.lot_number);
          // }
        });
        if (values.auction_images && values.auction_images.length > 0) {
          Array.from(values.auction_images).forEach((file, index) => {
            formData.append(`auction_images[${index}]`, file);
          });
        }

        formData.append("bid_increments", JSON.stringify(bidIncrements));

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/update/auctions/${editData.hash_id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "ngrok-skip-browser-warning": "69420",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log("Auction updated successfully:", response.data);
        if (response.status === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            close();
          }, 1000);
        }
        fetchAuctions();
      } catch (error) {
        console.error("Error updating auction:", error);
        toast.error("Error updating auction: ", error);
      }
    },
  });

  // Handling changes for lots
  useEffect(() => {
    if (editData?.lots) {
      setLots(editData.lots);
    }
  }, [editData]);

  const addLot = () => {
    const newLot = {
      title: "",
      description: "",
      images: [],
      category_id: "",
      estimate_from: "",
      estimate_to: "",
      lot_meta_title: "",
      lot_meta_description: "",
    };
    setLots([...lots, newLot]);
    formik.setFieldValue("lots", [...formik.values.lots, newLot]);
    setLotCount((prevCount) => prevCount + 1);
  };

  // Delete lot
  const deleteLot = (index) => {
    const updatedLots = lots.filter((_, i) => i !== index);
    setLots(updatedLots);
    formik.setFieldValue(
      "lots",
      formik.values.lots.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (editData?.bid_increments) {
      setBidIncrements(editData.bid_increments);
    }
  }, [editData]);

  const handleSlabChange = (index, field, value) => {
    const updatedBidIncrements = [...bidIncrements];
    updatedBidIncrements[index] = {
      ...updatedBidIncrements[index],
      [field]: value,
    };
    setBidIncrements(updatedBidIncrements);
  };

  const addSlab = () => {
    const newBidIncrement = {
      min_amount: "",
      max_amount: "",
      increment_amount: "",
    };
    setBidIncrements([...bidIncrements, newBidIncrement]);
  };

  const deleteSlab = (index) => {
    const updatedBidIncrements = bidIncrements.filter((_, i) => i !== index);
    setBidIncrements(updatedBidIncrements);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      formik.setFieldValue("auction_images", files);
      const newImages = Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
        });
      });
      Promise.all(newImages).then((images) => setCurrentImage(images));
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    formik.setFieldValue("is_shipping_available", checked ? 1 : 0);
  };

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    formik.setFieldValue("country", countryId);
    fetchStates(countryId);
  };

  const handleStateChange = (e) => {
    const stateid = e.target.value;
    // console.log(stateid, "stateid")
    formik.setFieldValue("state", stateid);
  };

  useEffect(() => {
    const fetchBiddingTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/bidding-types`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const { data } = response.data;
        setBidding(data);
        // console.log(data, "bidding type data");
      } catch (error) {
        console.error("Error fetching bidding types:", error);
      }
    };
    fetchBiddingTypes();
  }, []);

  const handleBiddChange = (event) => {
    const bidtype = event.target.value;
    // console.log(bidtype, "bidtype")
    formik.setFieldValue("bidding_type_id", bidtype);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const { data } = response.data;
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    return () => {
      // Clean up object URLs when the component unmounts
      formik.values.lots.forEach((lot) => {
        lot.imagePreviews?.forEach((previewUrl) =>
          URL.revokeObjectURL(previewUrl)
        );
      });
    };
  }, [formik.values.lots]);

  const handleImageChange = (index, fileIndex) => (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedImages = [...formik.values.lots[index].images];
      updatedImages[fileIndex] = newFile;
      // console.log(updatedImages, "updatedImages");
      formik.setFieldValue(`lots[${index}].images`, updatedImages);
    }
  };

  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <div className="text-end">
          <FontAwesomeIcon
            onClick={close}
            icon={faXmark}
            style={{ fontSize: "30px", color: "red" }}
          />
        </div>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                margin="normal"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Auctioneer"
                name="auctioneer"
                value={formik.values.auctioneer}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Start Date"
                name="start_date"
                type="datetime-local"
                value={formik.values.start_date}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="End Date"
                name="end_date"
                type="datetime-local"
                value={formik.values.end_date}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Auction Date Time Info"
                name="auction_date_time_info"
                value={formik.values.auction_date_time_info}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Preview Start Datetime"
                name="preview_start_datetime"
                type="datetime-local"
                value={formik.values.preview_start_datetime}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Checkout Date Time"
                name="checkout_date_time"
                type="datetime-local"
                value={formik.values.checkout_date_time}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Buyer Premium"
                name="buyer_premium"
                value={formik.values.buyer_premium}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                margin="normal"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Deposit Amount"
                name="deposit_amount"
                value={formik.values.deposit_amount}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Checkout Start Datetime"
                name="checkout_start_datetime"
                type="datetime-local"
                value={formik.values.checkout_start_datetime}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Checkout End Datetime"
                name="checkout_end_datetime"
                type="datetime-local"
                value={formik.values.checkout_end_datetime}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} variant="standard" sx={{ mb: 2 }}>
              <InputLabel id="country-label">
                Country<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                className="w-100"
                labelId="country-label"
                id="country"
                value={formik.values.country}
                onChange={handleCountryChange}
              >
                {countries.map((country) => (
                  <MenuItem key={country.hash_id} value={country.hash_id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} variant="standard">
              <InputLabel id="state-label">
                State<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                className="w-100"
                labelId="state-label"
                id="state"
                value={formik.values.state}
                onChange={handleStateChange}
              >
                {states.map((state) => (
                  <MenuItem key={state.hash_id} value={state.hash_id}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                label="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Zip Code"
                name="zip_code"
                value={formik.values.zip_code}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Terms and Conditions"
                name="terms_and_conditions"
                value={formik.values.terms_and_conditions}
                onChange={formik.handleChange}
                margin="normal"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Bidding Notice"
                name="bidding_notice"
                value={formik.values.bidding_notice}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <h3>Lots</h3>
              {lots.map((lot, index) => (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    padding: "16px",
                    mb: "16px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Title"
                        name={`lots[${index}].title`}
                        value={formik.values.lots[index].title || ""}
                        onChange={formik.handleChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Description"
                        name={`lots[${index}].description`}
                        value={formik.values.lots[index].description || ""}
                        onChange={formik.handleChange}
                        margin="normal"
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Estimate From"
                        name={`lots[${index}].estimate_from`}
                        value={formik.values.lots[index].estimate_from || ""}
                        onChange={formik.handleChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Estimate To"
                        name={`lots[${index}].estimate_to`}
                        value={formik.values.lots[index].estimate_to || ""}
                        onChange={formik.handleChange}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id={`lots[${index}].lot_meta_title`}
                        label="Lot meta Title"
                        name={`lots[${index}].lot_meta_title`}
                        placeholder="Enter lot meta title"
                        variant="outlined"
                        value={formik.values.lots[index].lot_meta_title || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id={`lots[${index}].lot_meta_description`}
                        label="Lot meta Description"
                        name={`lots[${index}].lot_meta_description`}
                        placeholder="Enter lot meta description"
                        variant="outlined"
                        value={
                          formik.values.lots[index].lot_meta_description || ""
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="normal"
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <h4>Upload New Images</h4>
                        <input
                          type="file"
                          multiple
                          onChange={(e) => {
                            const files = Array.from(e.target.files);
                            const existingImages =
                              formik.values.lots[index].images || [];
                            if (files.length > 0) {
                              formik.setFieldValue(`lots[${index}].images`, [
                                ...existingImages,
                                ...files,
                              ]);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <h4>Image Preview</h4> */}
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {(formik.values.lots[index].images || []).map(
                            (image, fileIndex) => {
                              if (typeof image === "string") {
                                return (
                                  <div
                                    key={fileIndex}
                                    style={{
                                      position: "relative",
                                      marginRight: "8px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <img
                                      src={image}
                                      alt={`lot-image-${fileIndex}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                      loading="lazy"
                                    />
                                    <input
                                      type="file"
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        opacity: 0,
                                      }}
                                      onChange={handleImageChange(
                                        index,
                                        fileIndex
                                      )}
                                    />
                                  </div>
                                );
                              }
                              // If it's a new file (Blob), create a URL and render it
                              else if (image instanceof Blob) {
                                const url = URL.createObjectURL(image);
                                return (
                                  <div
                                    key={fileIndex}
                                    style={{
                                      position: "relative",
                                      marginRight: "8px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <img
                                      src={url}
                                      alt={`lot-image-${fileIndex}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                      loading="lazy"
                                    />
                                    <input
                                      type="file"
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        opacity: 0,
                                      }}
                                      onChange={handleImageChange(
                                        index,
                                        fileIndex
                                      )}
                                    />
                                  </div>
                                );
                              }
                              return null;
                            }
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} variant="standard" sx={{ mb: 2 }}>
                      <InputLabel id={`category_id-${index}`}>
                        Category<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Select
                        className="w-100"
                        labelId={`category-${index}`}
                        id={`category-${index}`}
                        value={formik.values.lots[index]?.category_id || ""}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `lots[${index}].category_id`,
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.lots &&
                          formik.touched.lots[index] &&
                          Boolean(formik.errors.lots?.[index]?.category_id)
                        }
                        fullWidth
                      >
                        {categories.map((category) => (
                          <MenuItem
                            key={category.hash_id}
                            value={category.hash_id}
                          >
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <div className="px-3">
                      <Button
                        variant="outlined"
                        color="error"
                        className="delete-auction-lot"
                        onClick={() => deleteLot(index)}
                        sx={{ mt: 1 }}
                      >
                        Delete Lot
                      </Button>
                    </div>
                  </Grid>
                </Box>
              ))}
              <Button variant="outlined" onClick={addLot}>
                Add Lot
              </Button>
            </Grid>
            <Grid item xs={12}>
              <h3>Slabs</h3>
              {bidIncrements.map((slab, index) => (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    padding: "16px",
                    mb: "16px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="min_amount"
                        value={slab.min_amount || ""}
                        onChange={(e) =>
                          handleSlabChange(index, "min_amount", e.target.value)
                        }
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="max_amount"
                        value={slab.max_amount || ""}
                        onChange={(e) =>
                          handleSlabChange(index, "max_amount", e.target.value)
                        }
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="increment_amount"
                        value={slab.increment_amount || ""}
                        onChange={(e) =>
                          handleSlabChange(
                            index,
                            "increment_amount",
                            e.target.value
                          )
                        }
                        margin="normal"
                      />
                    </Grid>
                    <div className="px-3">
                      <Button
                        variant="outlined"
                        color="error"
                        className="delete-auction-lot"
                        onClick={() => deleteSlab(index)}
                        sx={{ mt: 1 }}
                      >
                        Delete Slab
                      </Button>
                    </div>
                  </Grid>
                </Box>
              ))}
              <Button variant="outlined" onClick={addSlab}>
                Add Slab
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <InputLabel id="bid-label">
                Bidding Type<span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                className="w-100"
                labelId="bid-label"
                id="bidding_type_id"
                value={formik.values.bidding_type_id}
                onChange={handleBiddChange}
              >
                {bidding.map((bid) => (
                  <MenuItem key={bid.hash_id} value={bid.hash_id}>
                    {bid.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {currentImage && currentImage.length > 0 && (
              <div>
                {currentImage &&
                  currentImage.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Preview ${index}`}
                      style={{
                        width: "100px",
                        maxHeight: "100px",
                        objectFit: "contain",
                        marginRight: "10px",
                      }}
                      loading="lazy"
                    />
                  ))}
              </div>
            )}
            <Grid item xs={12}>
              <div className="pt-1 auction-images-upload-label">
                <h4>Upload Auction Image</h4>
                <div className="file-images">
                  <input
                    type="file"
                    id="auction-images-upload"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "block", marginBottom: "10px" }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="meta_title"
                label="Auction meta title"
                placeholder="Auction meta title"
                variant="outlined"
                value={formik.values.meta_title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="meta_description"
                label="Auction meta description"
                placeholder="Auction meta description"
                variant="outlined"
                value={formik.values.meta_description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="card_verified_required"
                    checked={formik.values.card_verified_required}
                    onChange={(e) =>
                      formik.handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Card Verified Required"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="featured"
                    checked={formik.values.featured}
                    onChange={(e) =>
                      formik.handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Featured"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_shipping_available"
                    checked={formik.values.is_shipping_available === 1}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Is Shipping Available"
              />
            </Grid>
            {formik.values.is_shipping_available === 1 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pickup Info"
                  name="pickup_info"
                  value={formik.values.pickup_info || ""}
                  onChange={formik.handleChange}
                  margin="normal"
                  multiline
                  rows={4}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "#fff" }}
                type="submit"
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={close}
                style={{ marginLeft: "10px", color: "black" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default EditAuctionModal;
