import React, { useEffect, useState } from "react";
import "./Termcondition.css";
import { Helmet } from "react-helmet";
import axios from "axios";

function TermCondition() {
const [info, setinfo] = useState();
const url = window.location.href;

  const fetchinfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/info/terms-conditions`)
    setinfo(response.data.data)
    // console.log(response.data.data, "info")
  }
  useEffect(() => {
    fetchinfo()
  }, [])
  return (
    <>
      <Helmet>
      <title>{`${info?.meta_title || info?.name}`}</title>
        <meta
          name="description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:title"
          content={`${info?.meta_title || info?.name}`}
        />
        <meta
          property="og:description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:url"
          content={url}
        />
        <link
          rel="canonical"
          href={url}
        />
      </Helmet>
      <section className="term-condition mt-5">
        <div className="container">
          <div className="row">
           
            <p dangerouslySetInnerHTML={{ __html: info?.description || "" }} />
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p> */}

            {/* <p>
              These Terms of Use (these “Terms”), together with our Privacy
              Policy, govern your access to and use of https://auctionslive.net/
              (“Auction”) (collectively our “Sites”), including all services we
              now offer or may offer in the future through the Sites (our
              “Services”). Our Sites and Services are provided by 402 Ventures,
              LLC, a Delaware Limited Liability Company with a registered
              address of 251 Little Falls Drive, Wilmington, Delaware 19808, USA
              (“402,” “we,” “our,” or “us”). ”(Y)ou” or “your” refers to the
              user accessing and using our Sites and Services, and may refer to
              an individual or an entity.
            </p>
            <p>
              THESE TERMS ARE A BINDING CONTRACT BETWEEN YOU AND 402. PLEASE
              READ THEM CAREFULLY. BY ACCESSING AND USING OUR SITES AND
              SERVICES, WHETHER AS A VISITOR, BIDDER OR SELLER, YOU CONFIRM YOU
              HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS. WE
              RECOMMEND THAT YOU PRINT A COPY OF THESE TERMS FOR FUTURE
              REFERENCE.
            </p>
            <ol>
              <li>
                <p>
                  <strong>Services Generally.</strong> We provide an auction
                  platform, but we provide no auctioneering services. Any users
                  who choose to conduct or participate in auctions using our
                  Services are acting on their own behalves and are responsible
                  for any actions that they may take with respect to any
                  auctions or related transactions that they may enter into. We
                  do not represent any users of our Sites or Services, and we
                  are not a party to any transaction that may occur between
                  users. You are solely responsible for resolving any disputes
                  that you have with other users of our Sites or Services,
                  whether as a visitor, Bidder, or Seller. However, we may, in
                  our sole discretion, monitor or inquire about disputes or
                  issues between users in order to protect our Sites and
                  Services and address potential non-compliance of these Terms
                  by a user.
                </p>
                <p>
                  We are not in the business of the selling or buying of any
                  item(s) listed for auction (the “Listed Item(s) on a
                  commission basis. We do not take any Listed Item(s) on
                  consignment. All transactions and the terms of any agreements
                  that may occur between Sellers and Bidders or users of our
                  Sites or Services are determined solely through Seller and
                  Bidder, user to user interaction and agreement. We are not
                  acting as an employee, agent, representative, broker, dealer,
                  real estate broker, associate real estate broker, real estate
                  agent, real estate salesperson, mortgage broker, mortgage
                  lender, appraiser of real property, marketing agency, or
                  marketing agent of any Seller, Bidder, or user of our Sites or
                  Services. We do not produce, handle, hold, ship, feed,
                  process, distribute, transport, deliver, or take possession of
                  any Listed Item(s) at any time. We do not collect, handle or
                  remit any money, payments, checkoff fees, receivables or
                  proceeds from any transaction that may occur between Sellers
                  and Bidders for the sale or purchase of livestock, poultry, or
                  any other animals or any users of our Sites or Services
                  concerning the sale or purchase of livestock, poultry, or any
                  other animals. We are not registered with the U.S. Department
                  of Agriculture, Grain Inspection, Packers and Stockyards
                  Administration Packers and Stockyards Program, pursuant to the
                  provisions of the Packers and Stockyards Act, 1921, as amended
                  and supplemented (7 U.S.C. 181 et seq.). We are not bonded for
                  the protection of Sellers or Bidders or any user of our Sites
                  or Services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Additional Terms.</strong> When you access and use our
                  Sites and Services as a Seller or a Bidder, you also agree to
                  the following (each of which shall be incorporated, as
                  applicable, based on your use of our Services into these
                  Terms):
                  <ol>
                    <li>
                      <strong>Bidder User Terms</strong> (applicable if you
                      choose to participate in an auction using our Services as
                      a bidder or buyer ("Bidder")).
                    </li>
                    <li>
                      <strong>Seller User Terms</strong> (applicable if you
                      choose to conduct an auction using our Services as a
                      seller ("Seller")).
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <p>
                  <strong>Access and Use.</strong> While we use reasonable
                  efforts to provide our Sites and Services to you for so long
                  as you are compliant with these Terms, we do not guarantee
                  that they will be secure, or free from bugs or viruses. You
                  are responsible for configuring your information technology,
                  computer programs and platforms in order to access or use our
                  Sites and Services. You should use your own virus protection
                  software.
                </p>
                <p>
                  You may use our Sites and Services only if you can form a
                  binding contract with us, in compliance with these Terms and
                  all applicable local, state, national, and international laws,
                  rules and regulations. Any use of our Sites or Services by
                  anyone under the age of majority in his/her state of residence
                  is strictly prohibited. Our Services are not available to any
                  users we have previously removed or suspended from our
                  Services. We may suspend or terminate your access to our
                  Services at any time, for any reason (such as your material
                  breach of these Terms, our reasonable belief that it is
                  necessary to protect the safety or property of others, to
                  prevent fraud or for risk assessment purposes) or for no
                  reason. We reserve the right to limit your use of our Sites or
                  Services or terminate your account immediately, without notice
                  or refund of any amounts that you may have paid to us.
                </p>
              </li>
              <li>
                <p>
                  <strong>Accounts.</strong> If you use our Services as a Bidder
                  or Seller you must be registered with us and you will be
                  assigned to a user account. By registering with us and/or
                  using our Services as a Bidder or Seller, you represent and
                  warrant that you (a) will abide by all of the terms set forth
                  herein; (b) will not register for more than one account; (c)
                  will not provide false, deceptive, fraudulent, fictitious, or
                  misleading information about yourself or the Listed Item(s),
                  if any, that you list for auction; (d) will not create any
                  username or post any information on our Sites or Services that
                  is offensive, rude, disparaging, intended to deceive or delude
                  other users/visitors, threatening, abusive, harassing,
                  hateful, defamatory, pornographic, political, racist, or
                  otherwise unlawful or offensive, or that does not generally
                  pertain to the designated topic or theme; (e) will maintain
                  the security of any user identification code and/or password
                  associated with your account, notify us immediately if you
                  suspect that such security has been breached, and accept all
                  risks of authorized and unauthorized access to your account
                  and the information you provide to us; and (f) will not
                  violate the intellectual property or privacy rights of any
                  third party.
                </p>
                <p>
                  If you register or use our Services on behalf of a third party
                  (such as another person or legal entity), then you represent
                  and warrant that you are an authorized representative of that
                  person or entity with the authority to bind such person or
                  entity to these Terms, and you agree to these Terms on behalf
                  of that person or entity.
                </p>
                <p>
                  Accounts are non-transferable. Only you may use your user
                  account. You are liable for all activities that are undertaken
                  using your user account. We may verify your registration and
                  account by collecting contact information (including, but not
                  limited to, legal company name, street address, and employer
                  identification number) and banking information from you,
                  directly calling you to confirm the accuracy of your account
                  information, and taking whatever additional steps we deem
                  necessary.
                </p>
                <p>
                  Your account provides you access to participate in auctions or
                  list Listed Item(s) through our Services. It also gives you
                  access to additional services and functionality that we may
                  establish and maintain from time to time, in our sole
                  discretion, including but not limited to the ability:
                </p>
                <ol>
                  <li>for Bidders to view their bidding history; and</li>
                  <li>
                    for Bidders to create and add items to their own watchlist;
                  </li>
                  <li>
                    {" "}
                    for Sellers to view post-auction information for their
                    auctions;
                  </li>
                  <li>
                    for Bidders and Sellers to receive email updates and other
                    occasional news from our Sites.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Intellectual Property Rights.</strong>We are the owner
                  or the licensee of all intellectual property rights to and in
                  our Sites and Services, and in the content published on them.
                  Those rights are protected by copyright, trademark and other
                  applicable laws, as well as by treaties around the world. You
                  will not use, copy or otherwise take any action with respect
                  to our Sites and Services or such content except as expressly
                  permitted by these Terms. You will, likewise, respect the
                  intellectual property rights of others in exercising the
                  rights granted to you hereunder.
                </p>
              </li>
              <li>
                <p>
                  <strong>Acceptable Usage.</strong>You may access and use our
                  Services solely to list Listed Item(s) for sale, conduct or
                  participate in auctions and view Listed Item(s) for your
                  potential bidding and purchase. If you copy, redistribute or
                  resell any of our Services or any content made available to
                  you through our Sites or our Services, your right to access
                  and use both will cease immediately, and you must take down or
                  destroy any content you have obtained in violation of these
                  Terms.
                </p>
              </li>
              <li>
                <p>
                  <strong>Prohibited Uses.</strong> As part of our Sites and
                  Services, we may provide access to data, information, and
                  content relating to Listed Item(s) available for auction,
                  including without limitation current and historical Listed
                  Item(s) and related information, materials, and content (our
                  “Auction Information”). You must not:
                </p>
                <ol>
                  <li>
                    modify or alter any Auction Information, content, materials
                    or portions of our Sites or Services in any way;
                  </li>
                  <li>
                    use any Auction Information, illustrations, photographs,
                    video or audio sequences or any graphics separately from any
                    accompanying text, or any text separately from any
                    accompanying illustrations, photographs, video or audio
                    sequences or any graphics;
                  </li>
                  <li>
                    remove any indications of ownership from any Auction
                    Information or content which came from our Sites or
                    Services, or claim it as your own or as that of any other
                    entity or individual;
                  </li>
                  <li>
                    use any part of our Services, Auction Information, or
                    content on our Services for any purpose not expressly
                    provided for in Section 6 without obtaining a license to do
                    so from us or our licensors;
                  </li>
                  <li>
                    access, or attempt to access, parts of our Sites or Services
                    that are not intended for public use (including but not
                    limited to website administration areas);
                  </li>
                  <li>
                    modify, adapt or reverse engineer any aspect of our Sites,
                    Services or Auction Information;
                  </li>
                  <li>
                    conduct any web scraping, web harvesting, web data
                    extraction, or any other data scraping of Auction
                    Information or our Sites or Services;
                  </li>
                  <li>
                    use any robot, spider, scraper, data mining tool, data
                    gathering tools, data extraction tools, or any other
                    automated means to access our Sites or Services, or collect,
                    copy or record our Auction Information or content off our
                    Sites or Services;
                  </li>
                  <li>
                    take any action that imposes or may impose (in our sole
                    discretion) an unreasonable or disproportionately large load
                    on our infrastructure;
                  </li>
                  <li>
                    copy, download, distribute, transmit, reuse, report,
                    reproduce, modify, use, create derivative works from, or
                    publicly display any Auction Information or content of our
                    Sites or Services, including the text, images, audio, and
                    video, for public or commercial purposes, including
                    generating any reports or aggregating any data or content;
                  </li>
                  <li>
                    extract k. extract reports from our Auction Information or
                    Services other than records relating to your auction or bid
                    history;
                  </li>
                  <li>
                    take photos, screenshots or other images of our Auction
                    Information, Sites or Services or any content therein;
                  </li>
                  <li>
                    use our Auction Information or our Sites or Services in
                    competition with us, to develop competing products or
                    services, or otherwise to our detriment or commercial
                    disadvantage;
                  </li>
                  <li>
                    use our Auction Information or our Services for benchmarking
                    or competitive analysis of our Services;
                  </li>
                  <li>
                    identify us or display any portion of our Auction
                    Information, Sites or Services on any site or service that
                    disparages us or our products or services, or infringes any
                    of our intellectual property or other rights;
                  </li>
                  <li>
                    bypass any measures we may use to prevent or restrict access
                    to our Services, including our robot exclusion headers;
                  </li>
                  <li>
                    attempt to disrupt or interfere with the Auction Information
                    or our Sites or Services in any way or with another
                    user’s/visitor’s use of our Sites or Services, or use the
                    Auction Information or our Sites or Services as a means of
                    disrupting or interfering with other websites or networks;
                  </li>
                  <li>
                    probe, scan or test for vulnerabilities of our Sites,
                    Services, other websites, or any networks connected to them;
                  </li>
                  <li>
                    willfully corrupt any Auction Information, data, documents
                    or content available through our Sites or Services;
                  </li>
                  <li>
                    knowingly or recklessly transmit or distribute a virus into
                    our network and computer systems; or
                  </li>
                  <li>
                    assist, encourage or permit any other person to do any of
                    the acts described above.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Breach of Usage.</strong> All the prohibited uses set
                  forth in Section 7 above are forbidden regardless of the means
                  used, including but not limited to, hacking or by the
                  introduction of any worms, Trojan horses, viruses,
                  unauthorized, malicious or harmful code or other harmful
                  software. By breaching this provision, you may be committing a
                  criminal offence. We will report any such breach to the
                  relevant law enforcement authorities and we will co-operate
                  with those authorities by disclosing your identity to them. In
                  the event of such a breach, your right to use our Services
                  will cease immediately.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Uploading or Posting Content to Our Sites or Services
                  </strong>{" "}
                  We will collect data, content and information that you, as a
                  visitor, Seller or Bidder, provide us or that is collected by
                  us as you use the Sites or Services (“User Content”). You
                  hereby grant to us, and represent and warrant that you have
                  all rights necessary to grant to us, a worldwide, perpetual,
                  irrevocable, non-exclusive, sublicenseable, transferable, and
                  royalty-free right and license to use, store, copy,
                  distribute, and make available to third-parties all User
                  Content. We are not responsible for any loss or damage to your
                  User Content while it is in our possession, and you should
                  separately maintain copies of the User Content you provide to
                  us. We have the right to disclose your identity to any third
                  party who is claiming that any content submitted or uploaded
                  by you to our Sites or Services constitutes a violation of
                  their intellectual property rights, or of their right to
                  privacy. At any time, we have the right to remove any User
                  Content you submit to our Sites or Services if, in our
                  opinion, your post does not comply with these Terms.
                </p>
                <p>
                  WE TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY OF
                  YOUR USER CONTENT.
                </p>
              </li>
              <li>
                <p>
                  <strong>Suggestions and Submissions.</strong> We appreciate
                  and welcome your comments and feedback regarding our Sites and
                  Services. If you send us comments, questions, suggestions, or
                  ideas relating to our Sites and Services, you agree that such
                  content is not given in confidence, that we are not obliged to
                  keep such content secret, and that we are not obliged to
                  respond. For more information on how we use such content
                  please see our Privacy Policy and Cookies Policy. Please be
                  advised that if you send us creative ideas, suggestions,
                  inventions, or materials ("Creative Ideas"), we shall (i) own,
                  exclusively, all now known or later discovered rights to the
                  Creative Ideas and any creations, inventions, or derivative
                  works resulting from the Creative Ideas; (ii) not be subject
                  to any obligations of confidentiality and shall not be liable
                  for any use or disclosure of any Creative Ideas; and (iii) be
                  entitled to unrestricted use of the Creative Ideas for any
                  purpose whatsoever, commercial or otherwise, without
                  compensation to you or any other person.
                </p>
              </li>
              <li>
                <p>
                  <strong>Compliance With Laws.</strong> You are responsible
                  for, and agree to abide by, all local, state, federal and
                  international laws, rules and regulations applicable to your
                  use of our Sites and Services, and any other tools, services
                  or products offered on our Sites and Services and any
                  transaction you may enter into with other users in connection
                  with using our Sites or Services.
                </p>
                <p>
                  Any users located outside the United States must comply with
                  laws, regulations, rules and regulatory orders of the United
                  States, including the Foreign Corrupt Practices Act and the
                  U.S. Export Control Act, in addition to applicable local laws.
                  You may not use our Services if you are a resident of a
                  country embargoed by the United States, or are a foreign
                  person or entity blocked or denied by the United States
                  government or under similar laws or regulations applicable in
                  other jurisdictions. You are solely responsible for compliance
                  with any and all applicable laws, rules and regulations
                  relating to export and import requirements or restrictions.
                </p>
                <p>
                  Listed Items may be subject to EXPORT, RE-EXPORT, AND IMPORT
                  CONTROL LAWS AND REGULATIONS, PROHIBITIONS, RESTRICTIONS OR
                  COSTS. You are solely responsible for identifying and
                  complying with any applicable export/re-export/import laws,
                  rules, regulations, licensing, and/or Customs requirements
                  associated with Listed Items and any transaction you may enter
                  into with other users. If a Listed Item is seized or destroyed
                  by any governmental Customs agency or returned, we will not be
                  responsible for the losses, the Listed Item or shipping
                  refunds.
                </p>
              </li>
              <li>
                <p>
                  <strong>Taxes.</strong> We are not responsible for determining
                  whether tax applies to any transaction between users, or for
                  withholding, collecting, reporting, or remitting any tax
                  arising from any Listed Item(s). We do not give tax advice,
                  and nothing we say or do should be interpreted as such,
                  including our providing you with the opportunity to learn
                  about, and separately utilize, tax-related services or
                  solutions (for example, automated tax compliance software).
                  The payment and collection of applicable taxes (including
                  income, sales, property, excise, import, export, VAT and other
                  taxes or duties associated with the purchase/use and
                  sale/solicitation of any Listed Item(s)) must be, and are, the
                  exclusive responsibility of the Bidder and Seller, as
                  applicable. Generally speaking, it is the Seller's
                  responsibility to determine what taxes, if any, apply to a
                  transaction and to collect, report, and/or remit the correct
                  tax to the appropriate tax authority; however, to the extent
                  that you have any questions about your own payment,
                  collection, reporting or remittance obligations, you should
                  consult a qualified tax advisor.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Financing, Shipping, Insurance and Other Services.
                  </strong>{" "}
                  We may provide links on our Sites or Services to other
                  websites operated by us, third-party providers or affiliates
                  who provide various services or other offerings, including,
                  without limitation, financing, shipping, and insurance
                  services, pricing tools, and other offerings that might be of
                  interest to you, and only for the convenience of our users. In
                  consideration for providing any such link(s), we may receive a
                  fee from the third party(ies) or affiliates whose goods or
                  services you may choose to obtain. We will not be liable for
                  any acts or omissions of any such third-parties or affiliates,
                  whom you use at your own discretion and risk. Your access to
                  and use of our other websites and third-party or affiliate
                  services and websites may be subject to the applicable terms
                  and conditions of that respective website, third-party or
                  affiliate.
                </p>
              </li>
              <li>
                <p>
                  <strong>Disclaimer; No Warranty.</strong> TO THE FULLEST
                  EXTENT PERMITTED BY LAW, OUR SITES AND SERVICES, INCLUDING ALL
                  CONTENT, SOFTWARE, FUNCTIONS, MATERIALS AND INFORMATION MADE
                  AVAILABLE ON OR ACCESSED THROUGH OUR SITES OR SERVICES, ARE
                  PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
                  WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING
                  WITHOUT LIMITATION, IMPLIED WARRANTIES OF NON-INFRINGEMENT,
                  MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE ARE
                  SOLELY A PASSIVE CONDUIT TO PROVIDE CONTENT AND TO FACILITATE
                  COMMUNICATIONS BETWEEN A BIDDER AND A SELLER, AND IN NO EVENT
                  DO WE SELL ANY LISTED ITEM(S). WE MAKE NO WARRANTY THAT THE
                  LISTED ITEM(S) WILL PROPERLY FUNCTION OR OPERATE WHEN YOU
                  RECEIVE THEM, NOR THAT THEY WILL CONTINUE TO OPERATE OR
                  FUNCTION FOR ANY PERIOD OF TIME AFTER YOU RECEIVE THEM. TO THE
                  FULLEST EXTENT PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS
                  OR WARRANTIES OF ANY KIND WHATSOEVER FOR ANY PRODUCTS OR
                  SERVICES OR FOR THE SECURITY ASSOCIATED WITH THE TRANSMISSION
                  OF SENSITIVE INFORMATION THROUGH OUR SITES, SERVICES OR ANY
                  LINKED SITES, EVEN IF WE BECOME AWARE OF ANY ISSUES THEREWITH.
                </p>
                <p>
                  WE MAKE NO WARRANTY OF ANY KIND REGARDING THE TITLE, WEIGHT,
                  SIZE, COLOR, TYPE, NUMBER, QUALITY, STORAGE, CARE, FEEDING,
                  VERIFICATION, CERTIFICATION, BRANDING, CONDITION, SEX,
                  SOUNDNESS, SAFETY, HEALTH, NUTRITION OR NUTRITIONAL VALUE,
                  BEHAVIOR, PERSONALITY, MENTAL HEALTH, MENTAL CONDITION OR
                  SOUNDNESS, PHYSICAL APPEARANCE, ABILITY, AGE, PEDIGREE,
                  AUTHENTICITY OF PEDIGREES, BLOODLINES, BREED REGISTRATION OR
                  QUALIFICATION FOR BREED REGISTRATION, PHYSICAL PERFORMANCE AND
                  ABILITY, BEHAVIORAL PERFORMANCE AND WILLINGNESS TO PERFORM,
                  GENETIC PERFORMANCE, TRAINING, BREEDING ABILITY AND STATUS,
                  SHOWING ABLITY, RACING ABILITY, JUMPING ABILITY, REINING AND
                  CUTTING ABILITY, DRESSAGE ABILITY, RANCHING AND HERDING
                  ABILITY, AND FERTILITY OF ANY LISTED ITEM(S).
                </p>
                <p>
                  WE MAKE NO WARRANTY OF ANY KIND REGARDING THE GOOD AND
                  MARKETABLE TITLE OF THE LISTED ITEM(S). WE MAKE NO WARRANTY OF
                  ANY KIND CONCERNING THE CONDITION, ENVIRONMENTAL CONDITION,
                  ENVIRONMENTAL CLEAN-UP OR REMEDIATION, OR ENVIRONMENTAL
                  TESTING OF ANY LISTED ITEM(S). WE MAKE NO WARRANTY OF ANY KIND
                  THAT ANY LISTED ITEM(S) WILL BE FREE FROM HAZARDOUS OR TOXIC
                  MATERIALS, POLLUTANTS, CONTAMINANTS, WASTE, ASBESTOS,
                  PETROLEUM PRODUCTS, EROSION, WOOD DESTROYING INSECTS OR OTHER
                  INSECTS, FLOODING, WETLANDS, ENDANGERED SPECIES, AND ANY OTHER
                  TYPE OF CONDITION OR DEFECT. WE MAKE NO WARRANTY OF ANY KIND
                  REGARDING SOIL TESTING AND QUALITY, WATER RIGHTS, WATER
                  TESTING AND QUALITY, PROPERTY SITE GRADE, FLOODPLAIN, ZONING,
                  USE, EASMENTS, UTILITIES AND UTILITY LINES AND PROFITABILTY OF
                  ANY LISTED ITEM(S).
                </p>
                <p>
                  WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED ON OUR SITES OR
                  SERVICES, OR ANY MATERIALS OR CONTENT CONTAINED THEREIN, WILL
                  BE ACCURATE, COMPLETE, UP-TO-DATE, UNINTERRUPTED OR ERROR
                  FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT OUR SERVICES OR
                  THE SITES OR SERVERS THAT MAKE SUCH SERVICES AVAILABLE ARE
                  FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE
                  AND AGREE THAT ANY TRANSMISSION TO AND FROM OUR SITES AND
                  SERVICES IS NOT CONFIDENTIAL AND YOUR COMMUNICATIONS OR
                  CONTRIBUTED CONTENT MAY BE READ OR INTERCEPTED BY OTHERS. YOU
                  FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING
                  COMMUNICATIONS OR CONTENT TO US AND BY POSTING INFORMATION ON
                  OUR SITES OR SERVICES, INCLUDING LISTED ITEM(S), NO
                  CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER
                  RELATIONSHIP IS CREATED BETWEEN YOU AND US OTHER THAN PURSUANT
                  TO THESE TERMS
                </p>
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT HOLD OR SEEK TO
                  HOLD US RESPONSIBLE FOR THE CONTENT PROVIDED BY ANY USER,
                  INCLUDING, WITHOUT LIMITATION, ANY TRANSLATION THEREOF, AND
                  YOU FURTHER ACKNOWLEDGE AND AGREE THAT WE ARE NOT A PARTY TO
                  ANY LISTED ITEM(S) TRANSACTION OR OTHER TRANSACTION BETWEEN
                  USERS OF OUR SITES OR SERVICES. WE HAVE NO CONTROL OVER AND DO
                  NOT GUARANTEE THE SAFETY OF ANY TRANSACTION, LISTED ITEM(S) OR
                  THE TRUTH OR ACCURACY OF ANY LISTED ITEM(S) OR OTHER CONTENT
                  PROVIDED ON OUR SITES AND SERVICES.
                </p>
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT A REAL ESTATE
                  BROKER, ASSOCIATE REAL ESTATE BROKER, REAL ESTATE AGENT, REAL
                  ESTATE SALESPERSON, APPRAISER OF REAL PROPERTY, MORTGAGE
                  BROKER OR MORTGAGE LENDER. YOU FURTHER ACKNOWLEDGE AND AGREE
                  THAT WE DO NOT NEGOTIATE OR ATTEMPT TO NEGOTIATE THE LISTING,
                  SALE, PURCHASE, EXCHANGE, RENT, LEASE, OR OPTION FOR ANY REAL
                  ESTATE OR IMPROVEMENTS ON REAL ESTATE, OR ASSIST IN PROCURING
                  PROSPECTS FOR OR ACT AS A REFERRAL AGENT FOR THE PURPOSE OF
                  SECURING PROSPECTS FOR THE LISTING, SALE, PURCHASE, EXCHANGE,
                  RENTING, LEASING, OR OPTIONING OF ANY REAL ESTATE. YOU FURTHER
                  ACKNOWLEDGE AND AGREE THAT WE DO NOT AID OR ASSIST BORROWERS
                  IN OBTAINING, SOLICIT BORROWERS OR LENDERS FOR, OR NEGOTIATE
                  OR MAKE LOANS SECURED BY LIENS ON REAL PROPERTY.
                </p>
              </li>
              <li>
                <p>
                  <strong> Indemnification.</strong> You will indemnify, defend,
                  and hold harmless 402, its subsidiaries, affiliates, and each
                  of their respective officers, directors, employees, agents,
                  successors, and assigns (the “Indemnified Parties”) from and
                  against any claim or demand (including reasonable attorneys’
                  and experts’ fees and costs), made by any third party due to
                  or arising out of (i) your breach of these Terms; (ii) your
                  improper use of our Sites or Services; (iii) any personal
                  injury, death, or property damage caused by or arising out of
                  the listing, sale, transportation or use of any Listed
                  Item(s); or (iv) your violation of any law or rights of a
                  third party, including related to any User Content you provide
                  or post. We reserve the right, at our own expense, to assume
                  the exclusive defense and control of any matter otherwise
                  subject to indemnification by you, and you shall not in any
                  event settle any matter without our written consent. While
                  some jurisdictions may limit your liability hereunder, this
                  indemnity shall apply to the fullest extent permissible by
                  applicable law.
                </p>
              </li>
              <li>
                <p>
                  <strong>Release.</strong> IN THE EVENT THAT YOU HAVE A DISPUTE
                  WITH ONE OR MORE OTHER USERS OF OUR SITES OR SERVICES,
                  INCLUDING, WITHOUT LIMITATION, ANY OTHER BIDDER OR SELLER, ANY
                  THIRD PARTY VISITOR TO THE SITES OR THE PROVIDER OF ANY THIRD
                  PARTY WEBSITE OR APPLICATION THAT MAY BE LINKED TO OR FROM OUR
                  SITES OR SERVICES, YOU HEREBY AGREE TO RELEASE, REMISE AND
                  FOREVER DISCHARGE THE INDEMNIFIED PARTIES FROM ANY AND ALL
                  MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF
                  ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES,
                  COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER
                  KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE
                  TO, OR ARE CONNECTED WITH SUCH DISPUTE.
                </p>
              </li>
              <li>
                <p>
                  <strong>California Notice.</strong> IF YOU ARE A CALIFORNIA
                  RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH
                  SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                  CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST
                  IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, AND
                  THAT, IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS
                  OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
                </p>
              </li>
              <li>
                <p>
                  <strong>Limitation of Liability.</strong> TO THE FULLEST
                  EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE INDEMNIFIED
                  PARTIES BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL,
                  INCIDENTAL, OR PUNITIVE DAMAGES, WHETHER IN CONTRACT OR TORT
                  OR UNDER ANY OTHER THEORY OF LIABILITY, INCLUDING WITHOUT
                  LIMITATION, LOSS OF PROFITS, ANTICIPATED PROFITS, BUSINESS OR
                  SALES, ANY LOSS OF GOODWILL OR REPUTATION, OR THE COSTS OF
                  SUBSTITUTE GOODS OR SERVICES, EVEN IF THE INDEMNIFIED PARTIES,
                  OR AN AUTHORIZED REPRESENTATIVE THEREOF, HAS BEEN ADVISED OF
                  THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF, BASED ON, OR
                  RESULTING FROM (A) OUR SERVICES; (B) OUR SITES; (C) THESE
                  TERMS; (D) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY;
                  (E) YOUR, OR ANY THIRD-PARTY’S USE OF OUR SITES OR SERVICES,
                  OR THE SERVICES ANY THIRD PARTY PROVIDES, RELATED TO THE
                  BUSINESS WE OPERATE; (F) ANY USER INFORMATION OR
                  USER-CONTRIBUTED CONTENT; (G) ANY INTERACTION BETWEEN OUR
                  SITES OR SERVICES AND ANY THIRD PARTY SITES; AND (H) ANY
                  ACTUAL OR ATTEMPTED COMMUNICATION OR TRANSACTION, INCLUDING
                  WITHOUT LIMITATION, ANY PAYMENT OR PURCHASE TRANSACTION (EVEN
                  IF WE OR ANY THIRD PARTY RECEIVES A FEE IN CONNECTION
                  THEREWITH) BETWEEN USERS OF OUR SITES OR SERVICES.
                </p>
                <p>
                  IN NO EVENT SHALL THE TOTAL LIABILITY OF THE INDEMNIFIED
                  PARTIES TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
                  (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO,
                  NEGLIGENCE, PRODUCT LIABILITY OR OTHERWISE) ARISING FROM THESE
                  TERMS, THE PURCHASE, SALE OR USE OF ANY LISTED ITEM(S), OR
                  YOUR USE OF OUR SITES OR SERVICES EXCEED, IN THE AGGREGATE,
                  THE LESSER OF ONE THOUSAND DOLLARS ($1,000.00) OR THE TOTAL
                  FEES EARNED BY US IN CONNECTION WITH THE LISTED ITEM(S) TO
                  WHICH THE APPLICABLE CLAIM RELATES.
                </p>
              </li>
              <li>
                <p>
                  <strong>General</strong>
                </p>
                <ol>
                  <li>
                    <strong>Governing Law.</strong> These Terms are governed by
                    and construed in accordance with the laws of the State of
                    Nebraska, without regard to its conflict of law provisions.
                    You irrevocably agree to the exclusive jurisdiction of the
                    courts of Lancaster County, Nebraska, USA for any dispute
                    arising out of these Terms.
                  </li>
                  <li>
                    <strong>Translations.</strong> For all purposes, the English
                    language version of these Terms shall be the original,
                    governing instrument and understanding of the parties. In
                    the event of any conflict between the English language
                    version of these Terms and any subsequent translation into
                    any other language, the English language version shall
                    govern and control.
                  </li>
                  <li>
                    <strong>Amendments.</strong> We may modify, add, or remove
                    any portion of these Terms (including, without limitation,
                    any Bidder User Terms, Seller User Terms and/or additional
                    HiBid Terms and Conditions and/or Additional HiBid Terms and
                    Conditions for the sale and purchase of livestock, poultry
                    or any other animals and any Additional HiBid Terms and
                    Conditions for the sale and purchase of real estate.
                    incorporated herein), from time to time, in our sole
                    discretion, with or without notice to you, by posting such
                    change on our Sites and/or Services. Once posted, such
                    changes shall come into full force and effect.
                  </li>
                  <li>
                    <strong>Entire Terms. </strong>The introductory paragraph,
                    and any documents that are linked to or referenced in these
                    Terms, are hereby incorporated by reference and made part of
                    these Terms. Collectively, the foregoing constitutes the
                    entire agreement between the parties and supersede all prior
                    agreements and understandings, whether written or oral,
                    relating to the subject matter of these Terms.
                  </li>
                  <li>
                    <strong>Advice.</strong> Any guidance, discussions, or
                    advice provided by 402, its employees, independent
                    contractors and agents related to using our Sites and
                    Services, including but not limited to guidance for placing
                    and/or bidding on Listed Item(s), is intended for general
                    information purposes only. It is not intended as legal
                    advice, and you agree that you will independently evaluate
                    and not rely on such advice for any purpose.
                  </li>
                  <li>
                    <strong>Headings.</strong> Headings used in this agreement
                    are intended, and shall for all purposes be deemed to be,
                    for convenience only and shall have no force or affect
                    whatsoever in the interpretation of this agreement.
                  </li>
                  <li>
                    <strong>Reformation.</strong> If a court of competent
                    jurisdiction determines that the scope of any provision of
                    these Terms is too broad to be enforced, the court shall
                    reform such provisions to such narrower scope as it
                    determines to be enforceable.
                  </li>
                  <li>
                    <strong> Survival.</strong> Provisions of these Terms, which
                    by their nature should apply beyond their terms, will remain
                    in force after any termination or expiration of these Terms,
                    including but not limited to, Sections 5, 9, 10, 14, 15, 16,
                    17, 18, and 19.
                  </li>
                  <li>
                    <strong>Severability.</strong> If any provision of these
                    Terms is determined to be unenforceable for any reason and
                    is unable to be reformed by a court of competent
                    jurisdiction as provided above, such provision shall be
                    deemed separate and severable and the unenforceability of
                    any such provision shall not invalidate or render
                    unenforceable any of the remaining provisions hereof.
                  </li>
                  <li>
                    <strong>Successors and Assigns.</strong> These Terms shall
                    be binding upon and inure to the benefit of both parties and
                    their respective successors and permitted assigns. These
                    Terms may not be assigned by you without our prior written
                    consent. We may freely assign these Terms to any third party
                  </li>
                  <li>
                    <strong>Waiver.</strong> No delay or omission by a party in
                    exercising any right under these Terms shall operate as a
                    waiver of that or any other right. A waiver or consent given
                    by a party on any one occasion shall be effective only in
                    that instance and shall not be construed as a bar or waiver
                    of any right on any other occasion.
                  </li>
                  <li>
                    <strong>Conflicts.</strong> The provisions set forth in
                    these Terms shall prevail over a conflicting provision (if
                    any) set forth in the Bidder User Terms or Seller User
                    Terms.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>International Availability.</strong> We do not
                  represent that our Sites and Services are available worldwide,
                  or that bidding, or any proposed purchase and sale
                  transactions between users can occur in every location in the
                  world. You agree to comply with all local rules regarding
                  online conduct and acceptable content, including, without
                  limitation, applicable laws regarding the transmission of data
                  exported from the United States to the country in which you
                  reside. Please note that additional or different terms may
                  apply if you reside outside of the United States. Please click
                  on the location link below for more details: Jurisdictions.
                </p>
              </li>
              <li>
                <p>
                  <strong>Other Terms.</strong> Our Privacy Policy sets out the
                  terms on which we process any personal data we collect from
                  you, or that you provide to us. Our Cookies Policy sets out
                  our policy in relation to cookies.
                </p>
              </li>
            </ol> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default TermCondition;
