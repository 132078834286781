import axios from "axios";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function SingleBlog() {
  const [blog, setBlog] = React.useState([]);

  const url = window.location.href;

  const slug = window.location.pathname.split("/").pop();

  const fetchBlog = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/blog/${slug}`
      );
      // console.log("Blog slug:", response.data.data);
      setBlog(response.data.data);
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <>
    <Helmet>
      <title>{blog.meta_title}</title>
      <meta name="description" content={blog.meta_description} />
      <meta property="og:title" content={blog.meta_title} />
        <meta property="og:description" content={blog.meta_description}/>
        <meta property="og:url" content= {url}/>
        <link rel="canonical" href={url}/>
    </Helmet>
    <section className="blog-single mt-5 mb-5">
      <div className="container">
        <div className="row">
          <h1>{blog.name}</h1>
          <img
            src={`https://api.auctionslive.net/media/${blog.image}`}
            alt="blog"
            className="img"
            loading="lazy"
          />
          <p dangerouslySetInnerHTML={{ __html: blog.description }}></p>
        </div>
      </div>
    </section>
    </>
  );
}

export default SingleBlog;
