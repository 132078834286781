import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { Button, Card } from "react-bootstrap";

function Search() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const name = queryParams.get("name");
  const zipCode = queryParams.get("zip_code");
  const miles = queryParams.get("miles");

  const [suggestions, setSuggestions] = useState([]);

  const handleSearchSubmit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search`,
        {
          params: { name, zip_code: zipCode, miles },
          headers: { "ngrok-skip-browser-warning": "69420" },
        }
      );
      setSuggestions(response.data.data.data);
      // console.log(response.data.data.data, "events");
    } catch (error) {
      console.error("Error searching events:", error);
      toast.error("No data found");
    }
  };

  useEffect(() => {
    handleSearchSubmit();
  }, [name, zipCode, miles]);

  return (
    <>
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-center w-100 search-result gap-3 mt-4 mb-4">
          {suggestions.length > 0 ? (
            suggestions.map((item, index) => (
              <Card key={index}>
                <CardMedia
                  component="img"
                  image={`${process.env.REACT_APP_BASE_IMAGE_URL}/${item.auction_images[0].image_path}`}
                  alt={item.name}
                  loading="lazy"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    style={{ color: "#666", fontSize: 14 }}
                  >
                    {item.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to={`/auctions/${item.hash_id}`}
                    className="text-decoration-none text-dark d-flex"
                  >
                    <Button variant="primary" size="small">
                      View Details
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            ))
          ) : (
            <p>No results found</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Search;
