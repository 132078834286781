import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";

function EditCompany({ show, handleClose }) {
  const [userInfo, setUserInfo] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [statesProvienceList, setStatesProvienceList] = useState([]);
  const [id, setId] = useState(null);
  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");
  //   console.log(hash_id)

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`,
        {
          headers: { "ngrok-skip-browser-warning": "69420" },
        }
      );
      setCountries(response.data.data);
    } catch (error) {
      // console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId, type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;
      if (type === "stateprovience") {
        setStatesProvienceList(data);
      }
      if (type === "states") {
        setStates(data);
      }
    } catch (error) {
      // console.error("Error fetching states:", error);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auctioneer/users/${hash_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;

      const formattedData = {
        ...data,
        company: data?.detail?.company || "",
        bussiness_structure: data?.company_detail?.business_structure?.name || "",
        phone_number: data?.company_detail?.phone_number || "",
        customer_service_phone_number: data?.company_detail?.customer_service_phone_number || "",
        country: data?.company_detail?.country || "",
        state: data?.company_detail?.state || "",
        email: data?.company_detail?.email_address || "",
        fax_number: data?.company_detail?.fax_number || "",
        street: data?.company_detail?.street || "",
        city: data?.company_detail?.city || "",
        zip_postal_code: data?.company_detail?.zip_postal_code || "",
        website_address: data?.company_detail?.website_address || "",
        first_name: data?.company_representative?.first_name || "",
        middle_initial: data?.company_representative?.middle_initial || "",
        last_name: data?.company_representative?.last_name || "",
        bussiness_title: data?.company_representative?.business_title || "",
        preferred_phone_number:
          data?.company_representative?.preferred_phone_number || "",
        alternate_phone_number:
          data?.company_representative?.alternate_phone_number || "",
        preferred_email_address:
          data?.company_representative?.preferred_email_address || "",
        roles: data.roles[0]?.id,
        hide_username: data.settings?.hide_username || false,
        allow_magnifying_glass: data.settings?.allow_magnifying_glass || false,
        send_email_notifications:
          data.settings?.send_email_notifications || false,
        newsletter: data.settings?.newsletter || false,
      };

      setUserInfo(formattedData);
      setId(response.data.company_detail.id)
      // console.log(response.data, "all data");
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: userInfo?.company || "",
      // bussiness_structure: userInfo?.bussiness_structure || "",
      phone_number: userInfo?.phone_number || "",
      customer_service_phone_number:
        userInfo?.customer_service_phone_number || "",
      country: userInfo?.country || "",
      state_province: userInfo?.state || "",
      email_address: userInfo?.email || "",
      fax_number: userInfo?.fax_number || "",
      street: userInfo?.street || "",
      city: userInfo?.city || "",
      zip_postal_code: userInfo?.zip_postal_code || "",
      website_address: userInfo?.website_address || "",
      // first_name: userInfo?.first_name || "",
      // middle_initial: userInfo?.middle_initial || "",
      // last_name: userInfo?.last_name || "",
      // bussiness_title: userInfo?.bussiness_title || "",
      // preferred_phone_number: userInfo?.preferred_phone_number || "",
      // alternate_phone_number: userInfo?.alternate_phone_number || "",
      // preferred_email_address: userInfo?.preferred_email_address || "",
      // hide_username: userInfo?.hide_username || false,
      // allow_magnifying_glass: userInfo?.allow_magnifying_glass || false,
      // send_email_notifications: userInfo?.send_email_notifications || false,
      // newsletter: userInfo?.send_email_notifications || false,
      // role: userInfo?.roles,
    },
    onSubmit: async (values) => {
      // console.log(values);
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auctioneer/users/company/${id}`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        Swal.fire("Success", "Form Submitted Successfully!", "success");
        handleClose();
        fetchUserInfo();
      } catch (error) {
        console.error("Error submitting form:", error);
        Swal.fire("Error", "Error submitting form", "error");
      }
    },
  });

  useEffect(() => {
    if (formik.values.country) {
      fetchStates(formik.values.country, "stateprovience");
    } else {
      setStates([]);
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country) {
      fetchStates(formik.values.country, "states");
    } else {
      setStates([]);
    }
  }, [formik.values.country]);

  useEffect(() => {
    fetchUserInfo();
    fetchCountries();
    fetchStates();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} className="mt-3 edit-company">
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} className="p-4">
          <h5 className="pt-3">Edit Company Details</h5>
          {/* <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="company"
              placeholder="Company Name"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company}
              isInvalid={
                formik.touched.company && !!formik.errors.company
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.company}
            </Form.Control.Feedback> */}
          {/* <Form.Control
              type="text"
              name="bussiness_structure"
              placeholder="Bussiness Structure"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bussiness_structure}
              isInvalid={
                formik.touched.bussiness_structure &&
                !!formik.errors.bussiness_structure
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.bussiness_structure}
            </Form.Control.Feedback> */}
          {/* </Form.Group> */}
          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="phone_number"
              placeholder="Phone Number"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              isInvalid={
                formik.touched.phone_number && !!formik.errors.phone_number
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.phone_number}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="customer_service_phone_number"
              placeholder="Customer Service Phone Number"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customer_service_phone_number}
              isInvalid={
                formik.touched.customer_service_phone_number &&
                !!formik.errors.customer_service_phone_number
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.customer_service_phone_number}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Select
              name="country"
              onChange={formik.handleChange}
              value={formik.values.country}
              isInvalid={formik.touched.country && !!formik.errors.country}
            >
              <option>Country</option>
              {countries.map((country) => (
                <option key={country.hash_id} value={country.hash_id}>
                  {country.name}
                </option>
              ))}
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              {formik.errors.country}
            </Form.Control.Feedback>
            <Form.Select
              name="state_province"
              onChange={formik.handleChange}
              value={formik.values.state_province}
            >
              <option>State</option>
              {statesProvienceList.length > 0 ? (
                statesProvienceList.map((state) => (
                  <option key={state.hash_id} value={state.hash_id}>
                    {state.name}
                  </option>
                ))
              ) : (
                <option disabled>No states available</option>
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="email_address"
              placeholder="Email Address"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email_address}
              isInvalid={
                formik.touched.email_address && !!formik.errors.email_address
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email_address}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="fax_number"
              placeholder="Fax Number"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fax_number}
              isInvalid={
                formik.touched.fax_number && !!formik.errors.fax_number
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.fax_number}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="street"
              placeholder="Street"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.street}
              isInvalid={formik.touched.street && !!formik.errors.street}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.street}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="city"
              placeholder="City"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              isInvalid={formik.touched.city && !!formik.errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.city}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="zip_postal_code"
              placeholder="Zip"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zip_postal_code}
              isInvalid={
                formik.touched.zip_postal_code &&
                !!formik.errors.zip_postal_code
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.zip_postal_code}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="website_address"
              placeholder="Company Website"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.website_address}
              isInvalid={
                formik.touched.website_address &&
                !!formik.errors.website_address
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.website_address}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="first_name"
              placeholder="First Name"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              isInvalid={
                formik.touched.first_name && !!formik.errors.first_name
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.first_name}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="middle_initial"
              placeholder="Middle initial"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.middle_initial}
              isInvalid={
                formik.touched.middle_initial && !!formik.errors.middle_initial
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.middle_initial}
            </Form.Control.Feedback>
          </Form.Group> */}

          {/* <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="last_name"
              placeholder="Last Name"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              isInvalid={formik.touched.last_name && !!formik.errors.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.last_name}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="bussiness_title"
              placeholder="Bussiness Title"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bussiness_title}
              isInvalid={
                formik.touched.bussiness_title &&
                !!formik.errors.bussiness_title
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.bussiness_title}
            </Form.Control.Feedback>
          </Form.Group> */}

          {/* <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="preferred_phone_number"
              placeholder="Preferred Phone Number"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.preferred_phone_number}
              isInvalid={
                formik.touched.preferred_phone_number &&
                !!formik.errors.preferred_phone_number
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.preferred_phone_number}
            </Form.Control.Feedback>
            <Form.Control
              type="text"
              name="alternate_phone_number"
              placeholder="Alternate Phone Number"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.alternate_phone_number}
              isInvalid={
                formik.touched.alternate_phone_number &&
                !!formik.errors.alternate_phone_number
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.alternate_phone_number}
            </Form.Control.Feedback>
          </Form.Group> */}
          {/* 
          <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
            <Form.Control
              type="text"
              name="preferred_email_address"
              placeholder="Preferred Email Address"
              className="w-50"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.preferred_email_address}
              isInvalid={
                formik.touched.preferred_email_address &&
                !!formik.errors.preferred_email_address
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.preferred_email_address}
            </Form.Control.Feedback>
          </Form.Group> */}
          <Button variant="primary" type="submit" className="btn me-3">
            Submit
          </Button>
          {/* <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button> */}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditCompany;
