import React, { useState } from "react";
import AuctionMainConetnt from "./AuctionMainConetnt";
import LotsSideBar from "../Lots/LotsSideBar";

const AuctionsEvent = () => {
  const [lotSidebarState, setLotSidebarState] = useState({
    showSearchOptions: false,
    selectedAuctionStatus: "",
    selectedLotStatus: "",
    selectedCountry: "",
    selectedCountryState: "",
    selectedCategory: "",
    shippingAvailable: "",
  });
  const updateState = (key, value) => {
    setLotSidebarState((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <div className="auctions-event">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <LotsSideBar
              lotSidebarState={lotSidebarState}
              updateState={updateState}
            />
          </div>
          <div className="col-md-9">
            <div className="main-content">
              <AuctionMainConetnt lotSidebarState={lotSidebarState} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionsEvent;
