import React, { useEffect, useState } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";
import Logo from "../../assets/images/AdobeStock_607256595_Preview.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Header.css";

const Header2 = () => {
  const [categories, setCategories] = useState([]);
  const [hash, setHash] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [miles, setMiles] = useState("50");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            headers: { "ngrok-skip-browser-warning": "69420" },
          }
        );
        setHash(response.data.data.hash_id);
        setCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleMainCategoryExpand = (categoryHashId) => {
    navigate(`/lots?category=${categoryHashId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleZipChange = (e) => {
    setZipCode(e.target.value);
  };

  const handleMilesSelect = (selectedMiles) => {
    setMiles(selectedMiles);
  };

  useEffect(() => {
    setSuggestions([]);
  }, [location]);

  const handleSearchSubmit = () => {
    navigate(
      `/lots/search?name=${searchQuery}&zip_code=${zipCode}&miles=${miles}`
    );
  };

  return (
    <>
      <div className="header-two flex items-center justify-center relative">
        <div className="main-header flex items-center justify-center">
          <div className="container flex items-center justify-center">
            <div className="row">
              <div className="col-md-12 d-flex flex-column flex-md-row align-items-center">
                <div className="d-flex align-items-center justify-center gap-4 py-3 header-two-mobile w-full">
                  
                    <Link to="/" className="login-bidderr">
                      <img src={Logo} alt="logo" loading="lazy"/>
                    </Link>
                  <div className="header-two-container d-flex border-[1.5px] border-blue-400 rounded-3xl p-0">
                    <Dropdown className="filter-mobile border-r border-blue-400 rounded-0 h-full px-2 py-2 max-sm:border-0">
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-category"
                        className="shop-category bg-transparent border-0 font-semibold outline-0 font-bold mobile-view-distance shop-category-mobile"
                        style={{ color: "#2696ff", fontWeight: "700" }}
                      >
                        Shop By Category
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {categories &&
                          categories.map((category) => (
                            <Dropdown.Item
                              as="div"
                              key={category.hash_id}
                              onClick={() =>
                                handleMainCategoryExpand(category.hash_id)
                              }
                            >
                              {category.name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form className="filter-mobile d-flex">
                      <Form.Control
                        type="text"
                        placeholder="Search all of auctionslive.net"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="header-searching bg-transparent text-black rounded-none border-0 border-r-[1px] border-[#2696ff]"
                      />
                      <Form.Control
                        type="text"
                        placeholder="Zip Code"
                        value={zipCode}
                        onChange={handleZipChange}
                        className="zip-code border-0 border-blue-500 bg-transparent text-black pe-2"
                      />
                      <Dropdown onSelect={handleMilesSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-miles"
                          className="miles bg-transparent text-black border-0"
                        >
                          {miles} Miles
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="10">10 Miles</Dropdown.Item>
                          <Dropdown.Item eventKey="25">25 Miles</Dropdown.Item>
                          <Dropdown.Item eventKey="50">50 Miles</Dropdown.Item>
                          <Dropdown.Item eventKey="100">
                            100 Miles
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Button
                        type="button"
                        variant="primary"
                        className="search-btn h-[57px]  text-white"
                        style={{
                          borderTopRightRadius: "1.4rem",
                          borderBottomRightRadius: "1.4rem",
                          borderBottomLeftRadius: "0",
                          borderTopLeftRadius: "0",
                        }}
                        onClick={handleSearchSubmit}
                      >
                        Search
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>       
      </div>
    </>
  );
};

export default Header2;
