import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserEditModal.css";

const UserEditModal = ({ show, onHide, user, onUpdate }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [fax, setFax] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [hideUsername, setHideUsername] = useState(false);
  const [allowMagnifyingGlass, setAllowMagnifyingGlass] = useState(false);
  const [sendEmailNotifications, setSendEmailNotifications] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [isActive, setIsActive] = useState(0);

  useEffect(() => {
    if (user) {
      setUsername(user?.username || "");
      setEmail(user?.email || "");
      setAddress(user?.detail?.address || "");
      setCity(user?.detail?.city || "");
      setPostalCode(user?.detail?.postal_code || "");
      setPhone1(user?.detail?.phone1 || "");
      setPhone2(user?.detail?.phone2 || "");
      setFax(user?.detail?.fax || "");
      setSelectedCountry(user.country ? user.country.hash_id : "");
      setSelectedState(user.state ? user.state.hash_id : "");
      setHideUsername(user?.settings?.hide_username || false);
      setAllowMagnifyingGlass(user?.settings?.allow_magnifying_glass || false);
      setSendEmailNotifications(
        user?.settings?.send_email_notifications || false
      );
      setNewsletter(user?.settings?.newsletter || false);
      setSelectedRole(user?.roles?.[0]?.id || "");
      setIsActive(user.is_active || 0);
    }
  }, [user]);

  useEffect(() => {
    fetchCountries();
    fetchRoles();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    }
  }, [selectedCountry]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setCountries(response.data.data || []);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setStates(response.data.data || []);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchRoles = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/roles`,
        {
          headers,
        }
      );

      setRoles(response.data.roles || []);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
  const handleSave = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/users/update/${user.hash_id}`,
        {
          username,
          email,
          address,
          city,
          postal_code: postalCode,
          phone1,
          phone2,
          fax,
          country_id: selectedCountry,
          state_id: selectedState,
          hide_username: hideUsername ? 1 : 0,
          allow_magnifying_glass: allowMagnifyingGlass ? 1 : 0,
          send_email_notifications: sendEmailNotifications ? 1 : 0,
          newsletter: newsletter ? 1 : 0,
          role: selectedRole,
          is_active: isActive,
        },
        { headers }
      );

      if (response.data.status === "error") {
        toast.error(response.data.message || "User update failed.");
      } else {
        onUpdate();
        toast.success("User Updated Successfully");
        onHide();
      }
    } catch (error) {
      console.error("Error updating user:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const getRoleNames = () => {
    return roles
      .filter((role) =>
        user?.roles?.some((userRole) => userRole.id === role.id)
      )
      .map((role) => role.name)
      .join(", ");
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-y-scroll">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone 1</Form.Label>
              <Form.Control
                type="text"
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone 2</Form.Label>
              <Form.Control
                type="text"
                value={phone2}
                onChange={(e) => setPhone2(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Fax</Form.Label>
              <Form.Control
                type="text"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Select
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.hash_id} value={country.hash_id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.hash_id} value={state.hash_id}>
                    {state.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select Role</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Hide Username"
                checked={hideUsername}
                onChange={() => setHideUsername(!hideUsername)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Allow Magnifying Glass"
                checked={allowMagnifyingGlass}
                onChange={() => setAllowMagnifyingGlass(!allowMagnifyingGlass)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Send Email Notifications"
                checked={sendEmailNotifications}
                onChange={() =>
                  setSendEmailNotifications(!sendEmailNotifications)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Newsletter"
                checked={newsletter}
                onChange={() => setNewsletter(!newsletter)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={isActive}
                onChange={() => setIsActive(!isActive ? 1 : 0)}
              />
            </Form.Group>
          </Form>
          <div className="w-full border-t flex items-center justify-end pt-3 gap-2">
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UserEditModal;
