import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import "./Auctioner.css";
import Avatar from "react-avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const AuctionHeader = () => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNavigateToProfile = () => {
    navigate("/");
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavigateToSignIn = () => {
    navigate("/authentication/sign-in");
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const toggleSidebar = () => {
    // console.log("Sidebar toggle clicked");
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="mobile-sidebar">
      <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
      </button>

      <div
        className={`super-admin-sidebar ${isSidebarOpen ? "open" : "collapsed"
          }`}
      >
        {/* {isSidebarOpen && (
          <button className="close-sidebar-btn" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )} */}

        <div className="sidebar-content p-0 py-5 px-1">
          <div className="sidebar-header d-flex align-items-center mb-4">
            <div className="text-start">
              {username ? (
                <p
                  className="login-bidder text-uppercase p-0 px-2"
                  onClick={() => {
                    handleNavigateToProfile();
                    closeSidebar();
                  }}
                >
                  <Avatar name={username} size="30" round className="avatar" />{" "}
                  <b>{username}</b>
                </p>

              ) : (
                <p
                  className="login-bidder text-uppercase pb-3"
                  onClick={handleNavigateToSignIn}
                >
                  <b style={{ color: "black" }}>Login / New Bidder</b>
                </p>
              )}
            </div>
          </div>
          <nav className="sidebar-nav">
            <ul className="nav flex-column">
              <li className="nav-item px-2">
                <NavLink
                  to="/auctioner/dashboard"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Auctions
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/auctioner/Profile"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Profile
                </NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink
                  to="/auctioner/company"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={closeSidebar}
                >
                  Company
                </NavLink>
              </li>
              <li className="nav-item px-2" onClick={handleLogout}>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  Logout
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AuctionHeader;
