import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FormTextField from "./FormTextField";
import FormCheckbox from "./FormCheckbox";
import FormSelect from "./FormSelect";
import FormDateTimeInput from "./FormDateTimeInput";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const slabSchema = Yup.object().shape({
  min_amount: Yup.number()
    .required("Min amount is required")
    .positive("Min amount must be positive")
    .typeError("Min amount must be a number"),
  max_amount: Yup.number()
    .required("Max amount is required")
    .positive("Max amount must be positive")
    .typeError("Max amount must be a number"),
  increment_amount: Yup.number()
    .required("Increment amount is required")
    .positive("Increment amount must be positive")
    .typeError("Increment amount must be a number"),
});
const validationSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must not exceed 50 characters"),
  auctioneer: Yup.string()
    .required("Auctioneer is required")
    .min(2, "Auctioneer must be at least 2 characters")
    .max(50, "Auctioneer must not exceed 50 characters"),
  start_date: Yup.date()
    .required("Start date is required")
    .min(new Date(), "Start date cannot be in the past"),
  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End date must be after start date"),
  auction_date_time_info: Yup.string()
    .required("Auction date time info is required")
    .max(255, "Auction date time info must not exceed 255 characters"),
  preview_start_datetime: Yup.date()
    .required("Preview start datetime is required")
    .min(
      Yup.ref("start_date"),
      "Preview start datetime must be after start date"
    ),
  checkout_date_time: Yup.date()
    .required("Checkout datetime is required")
    .min(Yup.ref("end_date"), "Checkout datetime must be after end date"),
  location: Yup.string()
    .required("Location is required")
    .min(2, "Location must be at least 2 characters")
    .max(100, "Location must not exceed 100 characters"),
  buyer_premium: Yup.number()
    .required("Buyer premium is required")
    .typeError("Buyer premium must be a number")
    .min(0, "Buyer premium must be greater than or equal to 0")
    .max(100, "Buyer premium must not exceed 100"),
  description: Yup.string()
    .required("Description is required")
    .min(2, "Description must be at least 2 characters")
    .max(255, "Description must not exceed 255 characters"),
  card_verified_required: Yup.boolean(),
  deposit_amount: Yup.number()
    .required("Deposit amount is required")
    .typeError("Deposit amount must be a number")
    .min(0, "Deposit amount must be greater than or equal to 0"),
  checkout_start_datetime: Yup.date()
    .required("Checkout start datetime is required")
    .min(
      Yup.ref("checkout_date_time"),
      "Checkout start datetime must be after checkout date time"
    ),
  checkout_end_datetime: Yup.date()
    .required("Checkout end datetime is required")
    .min(
      Yup.ref("checkout_start_datetime"),
      "Checkout end datetime must be after checkout start datetime"
    ),
  city: Yup.string()
    .required("City is required")
    .min(2, "City must be at least 2 characters")
    .max(50, "City must not exceed 50 characters"),
  zip_code: Yup.string()
    .required("Zip Code is required")
    .matches(/^\d{5}$/, "Zip Code must be exactly 5 digits"),
  terms_and_conditions: Yup.string()
    .required("You must accept or reject the Terms and Conditions to proceed")
    .min(2, "Terms and Conditions must be at least 2 characters")
    .max(255, "Terms and Conditions must not exceed 255 characters"),
  featured: Yup.boolean(),
  bidding_notice: Yup.string()
    .required("Bidding notice is required")
    .min(2, "Bidding notice must be at least 2 characters")
    .max(255, "Bidding notice must not exceed 255 characters"),
  status: Yup.string().required("Status is required"),
  is_shipping_available: Yup.boolean(),
  bidding_type_id: Yup.string().required("Bidding Type is required"),
  slabs: Yup.array().of(
    Yup.object().shape({
      min_amount: Yup.number().required("Min amount is required"),
      max_amount: Yup.number()
        .required("Max amount is required")
        .moreThan(Yup.ref("min_amount"), "Max must be greater than Min"),
      increment_amount: Yup.number().required("Increment amount is required"),
    })
  ),

  lots: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      category_id: Yup.string().required("Category is required"),
      // estimate_from: Yup.string().required('estimate_from is required'),
      // estimate_to: Yup.string().required('estimate_to is required')

      estimate_from: Yup.string()
        .required("estimate_from is required")
        .matches(/^[0-9]+$/, "estimate_from must be a numeric value"),
      estimate_to: Yup.string()
        .required("estimate_to is required")
        .matches(/^[0-9]+$/, "estimate_to must be a numeric value"),
    })
  ),
});

const AuctionForm = ({ onClose }) => {
  const [lotCount, setLotCount] = useState(1);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bidding, setBidding] = useState([]);
  const [rows, setRows] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);

  const fetchAuctions = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get/auctions`,
        { headers }
      );
      setRows(response.data.auctions);
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  };
  useEffect(() => {
    fetchAuctions();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/countries`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const { data } = response.data;
        setCountries(data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchBiddingTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/bidding-types`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const { data } = response.data;
        setBidding(data);
      } catch (error) {
        console.error("Error fetching bidding types:", error);
      }
    };
    fetchBiddingTypes();
  }, []);

  const fetchStates = async (countryId) => {
    setLoadingStates(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;
      setStates(data);
    } catch (error) {
      console.error("Error fetching states:", error);
    } finally {
      setLoadingStates(false);
    }
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const { data } = response.data;
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const addLot = () => {
    const newLot = {
      title: "",
      description: "",
      images: [],
      estimate_from: "",
      estimate_to: "",
      lot_meta_title: "",
      lot_meta_description: "",
      category_id: "",
    };
    const newLots = [...formik.values.lots, newLot];
    formik.setFieldValue("lots", newLots);
    setLotCount((prevCount) => prevCount + 1);
  };

  const deleteLot = (index) => {
    const updatedLots = formik.values.lots.filter((lot, i) => i !== index);
    formik.setFieldValue("lots", updatedLots);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      auctioneer: "",
      start_date: "",
      end_date: "",
      bidding_type_id: "",
      auction_date_time_info: "",
      preview_start_datetime: "",
      checkout_date_time: "",
      location: "",
      buyer_premium: "",
      description: "",
      card_verified_required: false,
      deposit_amount: "",
      checkout_start_datetime: "",
      checkout_end_datetime: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
      terms_and_conditions: "",
      featured: false,
      bidding_notice: "",
      status: "",
      is_shipping_available: false,
      pickup_info: "",
      meta_title: "",
      meta_description: "",
      lot_meta_title: "",
      lot_meta_description: "",
      slabs: [{ min_amount: "", max_amount: "", increment_amount: "" }],
      lots: [
        {
          title: "",
          description: "",
          images: [],
          category_id: "",
          estimate_from: "",
          estimate_to: "",
        },
      ],
      auction_images: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log(values,"form dataaaaaaaa")
      const formData = new FormData();
      //   console.log(formData,"formdataformdataformdataformdataformdataformdataformdata")
      formData.append("name", values.name);
      formData.append("auction_date_time_info", values.auction_date_time_info);
      formData.append("auctioneer", values.auctioneer);
      formData.append("start_date", values.start_date);
      formData.append("end_date", values.end_date);
      formData.append("state", values.state);
      formData.append("bidding_type_id", values.bidding_type_id);
      formData.append("buyer_premium", values.buyer_premium);
      formData.append("description", values.description);
      formData.append("location", values.location);
      formData.append("city", values.city);
      formData.append("country", values.country);
      formData.append("zip_code", values.zip_code);
      formData.append("terms_and_conditions", values.terms_and_conditions);
      formData.append("bidding_notice", values.bidding_notice);
      formData.append("deposit_amount", values.deposit_amount);
      formData.append("meta_title", values.meta_title);
      formData.append("meta_description", values.meta_description);
      values.auction_images.forEach((file, index) => {
        formData.append(`auction_images[${index}]`, file);
      });
      values.slabs.forEach((slab, index) => {
        formData.append(
          `slabs[${index}][min_amount]`,
          parseFloat(slab.min_amount) || 0
        );
        formData.append(
          `slabs[${index}][max_amount]`,
          parseFloat(slab.max_amount) || 0
        );
        formData.append(
          `slabs[${index}][increment_amount]`,
          parseFloat(slab.increment_amount) || 0
        );
      });
      values.lots.forEach((lot, index) => {
        formData.append(`lots[${index}][title]`, lot.title);
        formData.append(`lots[${index}][description]`, lot.description);
        formData.append(
          `lots[${index}][category_id]`,
          lot.category_id || values.category_id
        );
        formData.append(`lots[${index}][estimate_from]`, lot.estimate_from);
        formData.append(`lots[${index}][estimate_to]`, lot.estimate_to);
        formData.append(`lots[${index}][lot_meta_title]`, lot.lot_meta_title);
        formData.append(
          `lots[${index}][lot_meta_description]`,
          lot.lot_meta_description
        );
        lot.images.forEach((image, imgIndex) => {
          formData.append(`lots[${index}][images][${imgIndex}]`, image);
        });
      });
      formData.append(
        "card_verified_required",
        values.card_verified_required ? 1 : 0
      );
      formData.append("featured", values.featured ? 1 : 0);
      formData.append(
        "is_shipping_available",
        values.is_shipping_available ? 1 : 0
      );
      formData.append(
        "status",
        values.status === "active" ? "active" : "pending"
      );
      if (values.is_shipping_available) {
        formData.append("pickup_info", values.pickup_info);
      }

      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        console.error("Access token not found in localStorage.");
        return;
      }

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create/auctions`,
          formData,
          { headers }
        );

        // console.log(response, "create auction");
        // resetForm();
        if (response.status === 201) {
          toast.success("Auction created successfully!");
          fetchAuctions();
          setTimeout(() => {
            onClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error submitting form:", error);
      }
    },
  });

  const handleCategoryChange = (event) => {
    formik.setFieldValue("category_id", event.target.value);
  };
  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    formik.setFieldValue("country", countryId);
    fetchStates(countryId);
  };
  const handleBiddChange = (event) => {
    // console.log(event.target.value, "bidding type");
    formik.setFieldValue("bidding_type_id", event.target.value);
  };
  const handleStateChange = (event) => {
    formik.setFieldValue("state", event.target.value);
  };
  const handleCancel = () => {
    onClose();
  };
  const handleNumericInput = (e) => {
    const { value } = e.target;
    if (/^\d*\.?\d*$/.test(value)) {
      formik.handleChange(e);
    }
  };
  const handleCheckboxChange = (fieldName) => (event) => {
    formik.setFieldValue(fieldName, event.target.checked);
  };
  const handleStatusChange = (event) => {
    formik.setFieldValue("status", event.target.value);
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const blobURLs = files.map((file) => new Blob([file], { type: file.type }));
    formik.setFieldValue("auction_images", blobURLs);
  };
  const addSlab = () => {
    formik.setFieldValue("slabs", [
      ...formik.values.slabs,
      { min_amount: "", max_amount: "", increment_amount: "" },
    ]);
  };
  const removeSlab = (index) => {
    const updatedSlabs = formik.values.slabs.filter((_, i) => i !== index);
    formik.setFieldValue("slabs", updatedSlabs);
  };

  return (
    <>
      <div className="w-full h-full relative flex items-start flex-col">
        <div className="text-end fixed ">
          <FontAwesomeIcon
            onClick={handleCancel}
            icon={faXmark}
            style={{ fontSize: "30px" }}
            className="text-[30px] border rounded-full p-2 fixed top-[2%] right-[2%] bg-white text-black z-50"
          />
        </div>
        <h2 className="font-bold">Add New Auction</h2>
        <form
          onSubmit={formik.handleSubmit}
          // style={{ display: "grid" }}
          className="relative w-100 mt-3 "
        >
          <FormTextField
            id="name"
            label="Name"
            placeholder="Enter name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="auctioneer"
            label="Auctioneer"
            placeholder="Enter auctioneer"
            variant="outlined"
            value={formik.values.auctioneer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.auctioneer && Boolean(formik.errors.auctioneer)
            }
            helperText={formik.touched.auctioneer && formik.errors.auctioneer}
            sx={{ mb: 2 }}
          />
          <FormDateTimeInput
            id="start_date"
            label="Start Date"
            type="datetime-local"
            variant="outlined"
            value={formik.values.start_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.start_date && Boolean(formik.errors.start_date)
            }
            helperText={formik.touched.start_date && formik.errors.start_date}
            inputProps={{ placeholder: "YYYY-MM-DDTHH:MM" }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormDateTimeInput
            id="end_date"
            label="End Date"
            type="datetime-local"
            variant="outlined"
            value={formik.values.end_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.end_date && Boolean(formik.errors.end_date)}
            helperText={formik.touched.end_date && formik.errors.end_date}
            inputProps={{ placeholder: "YYYY-MM-DDTHH:MM" }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="auction_date_time_info"
            label="Auction Date Time Info"
            placeholder="Enter auction date time info"
            variant="outlined"
            value={formik.values.auction_date_time_info}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.auction_date_time_info &&
              Boolean(formik.errors.auction_date_time_info)
            }
            helperText={
              formik.touched.auction_date_time_info &&
              formik.errors.auction_date_time_info
            }
            sx={{ mb: 2 }}
          />
          <FormDateTimeInput
            id="preview_start_datetime"
            label="Preview Start Date Time"
            type="datetime-local"
            variant="outlined"
            value={formik.values.preview_start_datetime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.preview_start_datetime &&
              Boolean(formik.errors.preview_start_datetime)
            }
            helperText={
              formik.touched.preview_start_datetime &&
              formik.errors.preview_start_datetime
            }
            inputProps={{ placeholder: "YYYY-MM-DDTHH:MM" }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormDateTimeInput
            id="checkout_date_time"
            label="Checkout Date Time"
            type="datetime-local"
            variant="outlined"
            value={formik.values.checkout_date_time}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.checkout_date_time &&
              Boolean(formik.errors.checkout_date_time)
            }
            helperText={
              formik.touched.checkout_date_time &&
              formik.errors.checkout_date_time
            }
            inputProps={{ placeholder: "YYYY-MM-DDTHH:MM" }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="location"
            label="Location"
            placeholder="Enter location"
            variant="outlined"
            value={formik.values.location}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="buyer_premium"
            label="Buyer Premium"
            placeholder="Enter buyer premium"
            variant="outlined"
            value={formik.values.buyer_premium}
            onChange={handleNumericInput}
            onBlur={formik.handleBlur}
            error={
              formik.touched.buyer_premium &&
              Boolean(formik.errors.buyer_premium)
            }
            helperText={
              formik.touched.buyer_premium && formik.errors.buyer_premium
            }
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="description"
            label="Description"
            placeholder="Enter description"
            variant="outlined"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="deposit_amount"
            label="Deposit Amount"
            placeholder="Enter deposit amount"
            variant="outlined"
            value={formik.values.deposit_amount}
            onChange={handleNumericInput}
            onBlur={formik.handleBlur}
            error={
              formik.touched.deposit_amount &&
              Boolean(formik.errors.deposit_amount)
            }
            helperText={
              formik.touched.deposit_amount && formik.errors.deposit_amount
            }
            sx={{ mb: 2 }}
          />
          <FormDateTimeInput
            id="checkout_start_datetime"
            label="Checkout Start Date Time"
            type="datetime-local"
            variant="outlined"
            value={formik.values.checkout_start_datetime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.checkout_start_datetime &&
              Boolean(formik.errors.checkout_start_datetime)
            }
            helperText={
              formik.touched.checkout_start_datetime &&
              formik.errors.checkout_start_datetime
            }
            inputProps={{ placeholder: "YYYY-MM-DDTHH:MM" }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormDateTimeInput
            id="checkout_end_datetime"
            label="Checkout End Date Time"
            type="datetime-local"
            variant="outlined"
            value={formik.values.checkout_end_datetime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.checkout_end_datetime &&
              Boolean(formik.errors.checkout_end_datetime)
            }
            helperText={
              formik.touched.checkout_end_datetime &&
              formik.errors.checkout_end_datetime
            }
            inputProps={{ placeholder: "YYYY-MM-DDTHH:MM" }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              id="country"
              value={formik.values.country}
              onChange={handleCountryChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
            >
              {countries.map((country) => (
                <MenuItem key={country.hash_id} value={country.hash_id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.country && formik.errors.country && (
              <Typography variant="caption" color="error">
                {formik.errors.country}
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              value={formik.values.state}
              onChange={handleStateChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
            >
              {states.map((state) => (
                <MenuItem key={state.hash_id} value={state.hash_id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.state && formik.errors.state && (
              <Typography variant="caption" color="error">
                {formik.errors.state}
              </Typography>
            )}
          </FormControl>
          <FormTextField
            id="city"
            label="City"
            placeholder="Enter city"
            variant="outlined"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="zip_code"
            label="Zip Code"
            placeholder="Enter zip code"
            variant="outlined"
            value={formik.values.zip_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
            helperText={formik.touched.zip_code && formik.errors.zip_code}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="terms_and_conditions"
            label="Terms and Conditions"
            placeholder="Enter terms and conditions"
            variant="outlined"
            value={formik.values.terms_and_conditions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.terms_and_conditions &&
              Boolean(formik.errors.terms_and_conditions)
            }
            helperText={
              formik.touched.terms_and_conditions &&
              formik.errors.terms_and_conditions
            }
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <FormTextField
            id="bidding_notice"
            label="Bidding Notice"
            placeholder="Enter bidding notice"
            variant="outlined"
            value={formik.values.bidding_notice}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bidding_notice &&
              Boolean(formik.errors.bidding_notice)
            }
            helperText={
              formik.touched.bidding_notice && formik.errors.bidding_notice
            }
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <FormSelect
            id="status"
            label="Status"
            value={formik.values.status}
            onChange={handleStatusChange}
            onBlur={formik.handleBlur}
            error={formik.touched.status && Boolean(formik.errors.status)}
            sx={{ mb: 2 }}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </FormSelect>

          {formik.values.is_shipping_available && (
            <FormTextField
              id="pickup_info"
              label="Pickup Info"
              placeholder="Enter Pickup Info"
              variant="outlined"
              value={formik.values.pickup_info}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.pickup_info && Boolean(formik.errors.pickup_info)
              }
              helperText={
                formik.touched.pickup_info ? formik.errors.pickup_info : ""
              }
              sx={{ mb: 2 }}
            />
          )}

          {formik.values.slabs.map((slab, index) => (
            <div
              key={index}
              style={{
                marginBottom: "20px",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              <div className="">
                <input
                  type="text"
                  name={`slabs[${index}].min_amount`}
                  value={slab.min_amount}
                  onChange={formik.handleChange}
                  placeholder="Min Amount"
                  className="slab-amount border rounded-sm"
                />
              </div>
              <input
                type="text"
                name={`slabs[${index}].max_amount`}
                value={slab.max_amount}
                onChange={formik.handleChange}
                placeholder="Max Amount"
                className="slab-amount border rounded-sm"
              />
              <input
                type="text"
                name={`slabs[${index}].increment_amount`}
                value={slab.increment_amount}
                onChange={formik.handleChange}
                placeholder="Increment Amount"
                className="slab-amount border rounded-sm"
              />
              <div className="">
                <Button
                  variant="outlined"
                  color="error"
                  className="delete-auction-lot"
                  onClick={() => removeSlab(index)}
                  sx={{ mt: 1 }}
                >
                  Remove Slab
                </Button>
              </div>
            </div>
          ))}
          <div className="add-lot mb-3">
            <Button
              className="add-lot-btn"
              variant="contained"
              onClick={addSlab}
              sx={{ mt: 2 }}
            >
              Add Slab
            </Button>
          </div>
          {formik.values.lots.map((lot, index) => (
            <Box
              key={index}
              style={{
                marginBottom: "20px",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id={`lots[${index}].title`}
                    label="Lot Title"
                    placeholder="Enter lot title"
                    variant="outlined"
                    name={`lots[${index}].title`}
                    value={lot.title}
                    onChange={(e) =>
                      formik.setFieldValue(`lots[${index}].title`, e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      Boolean(formik.errors.lots?.[index]?.title)
                    }
                    helperText={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      formik.errors.lots?.[index]?.title
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id={`lots[${index}].estimate_from`}
                    label=" estimate_from"
                    placeholder="Enter estimate from"
                    variant="outlined"
                    value={lot.estimate_from}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `lots[${index}].estimate_from`,
                        e.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      Boolean(formik.errors.lots?.[index]?.estimate_from)
                    }
                    helperText={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      formik.errors.lots?.[index]?.estimate_from
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id={`lots[${index}].estimate_to`}
                    label="estimate_to"
                    placeholder="Enter lot estimate_to"
                    variant="outlined"
                    value={lot.estimate_to}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `lots[${index}].estimate_to`,
                        e.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      Boolean(formik.errors.lots?.[index]?.estimate_to)
                    }
                    helperText={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      formik.errors.lots?.[index]?.estimate_to
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id={`lots[${index}].description`}
                    label="Lot Description"
                    placeholder="Enter lot description"
                    variant="outlined"
                    value={lot.description}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `lots[${index}].description`,
                        e.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      Boolean(formik.errors.lots?.[index]?.description)
                    }
                    helperText={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      formik.errors.lots?.[index]?.description
                    }
                    multiline
                    rows={3}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id={`lots[${index}].lot_meta_title`}
                    label="Lot meta title"
                    placeholder="Enter lot meta title"
                    variant="outlined"
                    value={lot.lot_meta_title}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `lots[${index}].lot_meta_title`,
                        e.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      Boolean(formik.errors.lots?.[index]?.lot_meta_title)
                    }
                    helperText={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      formik.errors.lots?.[index]?.lot_meta_title
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    id={`lots[${index}].lot_meta_description`}
                    label="Lot meta Description"
                    placeholder="Enter lot meta description"
                    variant="outlined"
                    value={lot.lot_meta_description}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `lots[${index}].lot_meta_description`,
                        e.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      Boolean(formik.errors.lots?.[index]?.lot_meta_description)
                    }
                    helperText={
                      formik.touched.lots &&
                      formik.touched.lots[index] &&
                      formik.errors.lots?.[index]?.lot_meta_description
                    }
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="file"
                    id={`lot-images-${index}`}
                    multiple
                    onChange={(e) => {
                      const files = Array.from(e.target.files);
                      const blobs = files.map(
                        (file) => new Blob([file], { type: file.type })
                      );
                      formik.setFieldValue(`lots[${index}].images`, blobs);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard" >
                    <InputLabel id={`category_id-${index}`}>Category</InputLabel>
                    <Select
                      labelId={`category-${index}`}
                      id={`category-${index}`}
                      value={lot.category_id}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `lots[${index}].category_id`,
                          e.target.value
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.lots &&
                        formik.touched.lots[index] &&
                        Boolean(formik.errors.lots?.[index]?.category_id)
                      }
                      fullWidth
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.hash_id} value={category.hash_id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.lots &&
                      formik.errors.lots &&
                      formik.errors.lots[index]?.category_id && (
                        <Typography variant="caption" color="error">
                          {formik.errors.lots[index].category_id}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="error"
                    className="delete-auction-lot"
                    onClick={() => deleteLot(index)}
                    sx={{ mt: 1 }}
                  >
                    Delete Lot
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
          <div className="add-lot">
            <Button
              className="add-lot-btn"
              variant="contained"
              onClick={addLot}
              sx={{ mt: 2 }}
            >
              Add Another Lot
            </Button>
          </div>
          <div className="" style={{ display: "none" }}>
            <FormTextField
              id="lots[0][category_id]"
              label="Lot Category ID"
              variant="outlined"
              value={formik.values.category_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.lots &&
                formik.touched.lots[0] &&
                Boolean(formik.errors.lots?.[0]?.category_id)
              }
              helperText={
                formik.touched.lots &&
                formik.touched.lots[0] &&
                formik.errors.lots?.[0]?.category_id
              }
              sx={{ mb: 2 }}
            />
          </div>
          <div className="" style={{ marginTop: "10px" }}>
            <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
              <InputLabel id="bid-label">Bidding Type</InputLabel>
              <Select
                labelId="bid-label"
                id="bidding_type_id"
                value={formik.values.bidding_type_id}
                onChange={handleBiddChange}
                error={
                  formik.touched.bidding_type_id &&
                  Boolean(formik.errors.bidding_type_id)
                }
              >
                {bidding.map((bid) => (
                  <MenuItem key={bid.hash_id} value={bid.hash_id}>
                    {bid.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.bidding_type_id &&
                formik.errors.bidding_type_id && (
                  <Typography variant="caption" color="error">
                    {formik.errors.bidding_type_id}
                  </Typography>
                )}
            </FormControl>
          </div>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="meta_title"
              label="Auction meta title"
              placeholder="Auction meta title"
              variant="outlined"
              value={formik.values.meta_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.meta_title && Boolean(formik.errors.meta_title)
              }
              helperText={formik.touched.meta_title && formik.errors.meta_title}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
             fullWidth
              margin="normal"
              id="meta_description"
              label="Auction meta description"
              placeholder="Auction meta description"
              variant="outlined"
              value={formik.values.meta_description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.meta_description &&
                Boolean(formik.errors.meta_description)
              }
              helperText={
                formik.touched.meta_description && formik.errors.meta_description
              }
              multiline
              rows={3}
            />
          </Grid>
          <div className="pt-1 auction-images-upload-label">
            <label htmlFor="auction-images-upload">Upload Auction Images</label>
            <div className="file-images">
              <input
                type="file"
                id="auction-images-upload"
                multiple
                onChange={handleFileChange}
                style={{ display: "block", marginBottom: "10px" }}
              />
            </div>
          </div>
          <FormCheckbox
            id="featured"
            label="Featured"
            checked={formik.values.featured}
            onChange={handleCheckboxChange("featured")}
            sx={{ mb: 2 }}
          />
          <FormCheckbox
            id="is_shipping_available"
            label="Is Shipping Available"
            checked={formik.values.is_shipping_available}
            onChange={handleCheckboxChange("is_shipping_available")}
            sx={{ mb: 2 }}
          />
          <FormCheckbox
            id="card_verified_required"
            label="Card Verified Required"
            checked={formik.values.card_verified_required}
            onChange={handleCheckboxChange("card_verified_required")}
            sx={{ mb: 2 }}
          />
          <div className="mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
AuctionForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AuctionForm;
