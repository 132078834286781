import React from "react";

const ShippingToggle = ({ shippingAvailable, setShippingAvailable }) => {
  return (
    <div className="sidebar-item d-flex align-items-center justify-content-between font-bold uppercase">
      <span className="">Shipping Available</span>
      <div className="form-check form-switch ">
        <input
          type="checkbox"
          className="form-check-input"
          checked={shippingAvailable}
          onChange={() => setShippingAvailable(!shippingAvailable)}
        />
      </div>
    </div>
  );
};

export default ShippingToggle;
