import React from "react";
import "./Footer.css";
import ImgFooter from "../../assets/images/AdobeStock_607256595_Preview.jpeg";
import Facebook from "../../assets/images/facebook.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import Linkedin from "../../assets/images/linkedin.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEnvelope,
  faLocationArrow,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="container-fluid footer">
      <div className="row footer-services">
        <div className="col-md-4">
            <div className="footer-img footer-img-mobile text-center pt-5 flex items-center justify-center">
              <Link to="/">
                <img
                  src={ImgFooter}
                  alt="footer-img"
                  width={100}
                  loading="lazy"
                />
              </Link>
          </div>
        </div>
        <div className="col-md-4 footer-links-services">
          <div className="services ">
            <p>Our Services</p>
            <ul className="links">
              <li className="useful-list">
                <Link to="/info/about-us" className="link">
                  About Us
                </Link>
              </li>
              <li className="useful-list">
                <Link to="/info/contact-us" className="link">
                  Contact Us
                </Link>
              </li>
              <li className="useful-list">
                <Link to="/info/privacy-policy" className="link">
                  Privacy & Policy
                </Link>
              </li>
              <li className="useful-list">
                <Link to="/info/terms-condition" className="link">
                  Terms & Conditions
                </Link>
              </li>
              <li className="useful-list">
                <Link to="/info/help" className="link">
                  Help
                </Link>
              </li>
              {/* <li className='useful-list'><Link to="/info/faq" className="link ">FAQ's</Link></li> */}
            </ul>
          </div>
        </div>
        <div className="col-md-4 footer-contact-mobile px-3">
          <p>Contact Us</p>
          <div className="d-flex contact-btns-footer-mobile">
            <div className="calling-img">
              <FontAwesomeIcon
                icon={faPhone}
                className="footer-icons call-icon"
              />
            </div>
            <div className=" text-start contact-box-text-mobile">
              <span style={{ color: "#fff" }}> +651-334-8312</span>
            </div>
          </div>
          <div className="d-flex pt-3 contact-btns-footer-mobile">
            <div className="message-img footer-icon-box">
              {/* <img src={Message} alt='message' /> */}
              <FontAwesomeIcon icon={faEnvelope} className="footer-icons " />
            </div>
            <div className="px-2 footer-email text-start contact-box-text-mobile">
              <span>Email: info@auctionslive.net</span>
            </div>
          </div>
          <div className="d-flex pt-3 contact-btns-footer-mobile">
            <div className="send-img footer-icon-box">
              {/* <img src={Send} alt='send' /> */}
              <FontAwesomeIcon
                icon={faLocationArrow}
                className="footer-icons"
              />
            </div>
            <div className="px-2 printing footer-icon-box text-start contact-box-text-mobile">
              <span>7465 pinecrest rd pine springs mn 55115</span>
            </div>
          </div>
          <div className="d-flex pt-3 contact-btns-footer-mobile">
            <div className="clock-img footer-icon-box">
              <FontAwesomeIcon icon={faClock} className="footer-icons" />
            </div>
            <div className="px-2 timing footer-icon-box text-start contact-box-text-mobile">
              <span>Sunday - Monday 9am to 6pm appointments required </span>
            </div>
          </div>
        </div>
        <hr className="hr-line-footer" />
        <div className="row">
          <div className="col-md-6">
            <p className="text-left reserved">
              auctionslive © 2024 | All Rights Reserved
            </p>
          </div>
          <div className="col-md-6">
            <div className="footer-icon">
              <ul className="social-icons">
                <li>
                  <img
                    src={Linkedin}
                    alt="LinkedIn"
                    width={30}
                    height={30}
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src={Twitter}
                    alt="Twitter"
                    width={30}
                    height={30}
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src={Facebook}
                    alt="Facebook"
                    width={30}
                    height={30}
                    loading="lazy"
                  />
                </li>
                <li>
                  <img
                    src={Instagram}
                    alt="Instagram"
                    width={30}
                    height={30}
                    loading="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
