import axios from "axios";
import React, { useEffect, useState } from "react";
import EditCompany from "./EditCompany";
import "./Detail.css";

function Companydetails() {
  const [userInfo, setUserInfo] = useState(null);
  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");
  const [show, setShow] = useState(false);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auctioneer/users/${hash_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(response.data, "all data");
      setUserInfo(response.data);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="company-details  mb-5">
        <div className="container">
          <div className="row p-5">
            <div className="edit-btn mb-4">
              <button className="btn btn-primary edited" onClick={handleShow}>
                Edit Details
              </button>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Company Name</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">{userInfo?.detail?.company}</p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Bussiness Structure</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.business_structure?.name}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Phone Number</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.phone_number}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Customer Service Phone Number</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.customer_service_phone_number}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Email Address</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.email_address}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Fax Number</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.fax_number}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Country</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">{userInfo?.country?.name}</p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">State</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">{userInfo?.state?.name}</p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Street</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.street}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">City</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.city}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Zip</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.zip_postal_code}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Company Website</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_detail?.website_address}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">First Name</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.first_name}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Middle initial</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.middle_initial}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Last Name</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.last_name}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Bussiness Title</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.business_title}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Preferred Phone Number</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.preferred_phone_number}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Alternate Phone Number</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.alternate_phone_number}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Preferred Email Address</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  {userInfo?.company_representative?.preferred_email_address}
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">License</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  <a
                    href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_one}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0178FB' }}
                  >
                    {userInfo?.image_path_one ? `${userInfo?.image_path_one}` : "No document available"}
                  </a>
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Business Registrations</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  <a
                    href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_two}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0178FB' }}
                  >
                    {userInfo?.image_path_two ? `${userInfo?.image_path_two}` : "No document available"}
                  </a>
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">DBA license</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  <a
                    href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_three}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0178FB' }}
                  >
                    {userInfo?.image_path_three ? `${userInfo?.image_path_three}` : "No document available"}
                  </a>
                </p>
              </div>
            </div>
            <div className="details d-flex justify-content-center align-items-center w-50">
              <div className="w-50">
                <h5 className="text-start">Auctioneers license</h5>
              </div>
              <div className="w-50">
                <p className="text-start mb-0">
                  <a
                    href={`${process.env.REACT_APP_BASE_IMAGE_URL}/media/user/${userInfo?.image_path_four}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0178FB' }}
                  >
                    {userInfo?.image_path_four ? `${userInfo?.image_path_four}` : "No document available"}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EditCompany show={show} handleClose={handleClose} />
    </>
  );
}

export default Companydetails;
