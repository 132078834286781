import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Alert, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Configuration.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditModal from "./EditModal";
import ViewModal from "./ViewModal";
import ConfirmModal from "./ConfirmModal";

const ITEMS_PER_PAGE = 20;

const Configuration = () => {
  const [configurations, setConfigurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [newValue, setNewValue] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchConfigurations = async () => {
    setLoading(true);
    setRefreshing(true);
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setError("Access token not found.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/configurations`,
        { headers }
      );
      if (response.data.status === "success") {
        setConfigurations(response.data.data);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("An error occurred while fetching configurations.");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const handleEdit = (config) => {
    setSelectedConfig(config);
    setNewValue(config.value);
    setShowEditModal(true);
  };

  const handleView = (config) => {
    setSelectedConfig(config);
    setShowViewModal(true);
  };

  const handleSaveChanges = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setError("Access token not found.");
      setLoading(false);
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/configurations/update/${selectedConfig.hash_id}`,
        {
          value: newValue,
        },
        { headers }
      );

      toast.success("Configuration updated successfully!");
      fetchConfigurations();
    } catch (error) {
      toast.error("An error occurred while updating the configuration.");
    } finally {
      setShowEditModal(false);
      setShowConfirmModal(false);
    }
  };
  const totalPages = Math.ceil(configurations.length / ITEMS_PER_PAGE);
  const currentItems = configurations.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  if (loading)
    return (
      <div className="loader-overlay">
        <div className="spinner-border-custom"></div>
      </div>
    );
  if (error)
    return (
      <div className="text-center mt-5">
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  return (
    <div className={`container p-5 ${refreshing ? "fade-out" : "fade-in"}`}>
      <h2>Configurations</h2>
      {configurations.length === 0 ? (
        <div className="text-center mt-5">
          <Alert variant="info">No data found</Alert>
        </div>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            className="configuration-table border mt-5 mb-5"
          >
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((config) => (
                <tr key={config.hash_id}>
                  <td>{config.key}</td>
                  <td>{config.value}</td>
                  <td>
                    <button
                      className="btn btn-link btn-sm"
                      onClick={() => handleView(config)}
                    >
                      <FontAwesomeIcon icon={faEye} color="green" />
                    </button>
                    <button
                      className="btn btn-link btn-sm"
                      onClick={() => handleEdit(config)}
                    >
                      <FontAwesomeIcon icon={faEdit} color="blue" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination className="justify-content-center">
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            />
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (page) => (
                <Pagination.Item
                  key={page}
                  active={page === currentPage}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Pagination.Item>
              )
            )}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            />
          </Pagination>
        </>
      )}
      <ViewModal
        show={showViewModal}
        onHide={() => setShowViewModal(false)}
        config={selectedConfig}
      />
      <EditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        config={selectedConfig}
        newValue={newValue}
        onNewValueChange={setNewValue}
        onSave={() => setShowConfirmModal(true)}
      />
      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleSaveChanges}
      />
      <ToastContainer />
    </div>
  );
};

export default Configuration;
