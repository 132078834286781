import React from "react";
import Slider from "react-slick";

const Carousel = ({ items, isSidebarVisible }) => {
  // console.log(items, "items")
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isSidebarVisible ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isSidebarVisible ? 2 : 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.id} className="carousel-item">
            <div className="item py-2 me-3 shadow-sm">
              <div className="text-center flex items-center justify-center flex-col">
                <div className="image-lots w-full flex items-center justify-center overflow-visible">
                  <img
                    src={`${process.env.REACT_APP_BASE_IMAGE_URL}${item.images[0].image_path}`}
                    alt="lots"
                    style={{ width: isSidebarVisible ? "50%" : "70%" }}
                    className="hover:scale-105"
                    loading="lazy"
                  />
                </div>
                <div className="pt-5">
                  <h3>{item.title}</h3>
                  <p>
                    {item?.description?.length > 30
                      ? item?.description?.substring(0, 30) + "..."
                      : item?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
