import React, { useRef, useState } from "react";
import Peer from "simple-peer";
import axios from "axios";
import Pusher from "pusher-js";
import { toast } from "react-toastify";

window.Pusher = Pusher;

const Viewer = () => {
  const viewerRef = useRef(null);
  const [broadcasterPeer, setBroadcasterPeer] = useState(null);
  const [broadcasterId, setBroadcasterId] = useState(null);

  const auth_user_id = localStorage.getItem("hash_id");


  const stream_id =
    "Dg1I1pwfwwouX1cTfk-bMjE5aTBobnVZL1k1Rm03VFcvMVNTYXc9PQ12acde2";

  const joinBroadcast = () => {
    if (!auth_user_id) {
      toast.error("Please login first");
      return;
    }
    initializeStreamingChannel();
    initializeSignalOfferChannel();
  };

  const initializeStreamingChannel = () => {
    // console.log(`Joining streaming channel: streaming-channel.${stream_id}`);
    window.Echo.join(`streaming-channel.${stream_id}`);
  };

  const initializeSignalOfferChannel = () => {
    // console.log(
    //   `Listening for Signal Offers on channel: stream-signal-channel.${auth_user_id}`
    // );

    window.Echo.private(`stream-signal-channel.${auth_user_id}`)
      .listen("StreamOffer", ({ data }) => {
        // console.log("Received Signal Offer:", data);
        setBroadcasterId(data.broadcaster);
        createViewerPeer(data.offer, data.broadcaster);
      })
      .error((error) => {
        console.error("Error joining private channel:", error);
      });

    // console.log("Listener initialized");
  };

  const createViewerPeer = (incomingOffer, broadcaster) => {
    // console.log("Creating Viewer Peer with offer:", incomingOffer);
    const peer = new Peer({
      initiator: false,
      trickle: false,
    });
    handlePeerEvents(peer, incomingOffer, broadcaster, removeBroadcastVideo);
    setBroadcasterPeer(peer);
  };

  const handlePeerEvents = (
    peer,
    incomingOffer,
    broadcaster,
    cleanupCallback
  ) => {
    peer.on("signal", (data) => {
      // console.log("Sending answer:", { broadcaster, answer: data });
      axios
        .post("https://auctionslive.net/stream-answer", {
          broadcaster,
          answer: data,
        })
        .then((res) => {
          // console.log("Answer sent:", res.data);
          // console.log(res.data, "videooooooooo")
        })
        .catch((err) => {
          console.error("Error sending answer:", err);
        });
    });

    peer.on("stream", (stream) => {
      // console.log("Stream received from broadcaster:", stream);
      if (stream) {
        viewerRef.current.srcObject = stream;
        viewerRef.current.play();
      } else {
        console.error("Received an invalid stream");
      }
    });
    peer.on("connect", () => {
      // console.log("Viewer Peer connected");
    });
    peer.on("close", () => {
      // console.log("Viewer Peer closed");
      cleanupCallback();
    });
    peer.on("error", (err) => {
      console.error("Peer connection error:", err);
    });
    console.log("Signaling with incoming offer:", incomingOffer);
    peer.signal(incomingOffer);
  };

  const removeBroadcastVideo = () => {
    // console.log("Removing Broadcast Video");
    alert("Livestream ended by broadcaster");
    if (viewerRef.current && viewerRef.current.srcObject) {
      const tracks = viewerRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      viewerRef.current.srcObject = null;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <button className="btn btn-success" onClick={joinBroadcast}>
            Join Stream
          </button>
          <br />
          <video autoPlay ref={viewerRef} style={{ width: "350px", height: "200px" }} controls className="mt-3 mb-3"></video>
        </div>
      </div>
    </div>
  );
};

export default Viewer;
