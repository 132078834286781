import React, { useEffect, useState } from "react";
import "./Editinfo.css";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

function Editinfo() {
  const [countries, setCountries] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [states, setStates] = useState([]);
  const [statesProvienceList, setStatesProvienceList] = useState([]);
  const hash_id = localStorage.getItem("hash_id");
  const token = localStorage.getItem("access_token");
  // console.log(token)

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`,
        {
          headers: { "ngrok-skip-browser-warning": "69420" },
        }
      );
      setCountries(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryId, type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const { data } = response.data;
      if (type === "stateprovience") {
        setStatesProvienceList(data);
      }
      if (type === "states") {
        setStates(data);
      }
    } catch (error) {
      // console.error("Error fetching states:", error);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/buyer/users/${hash_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      }
      );
      const data = response.data;

      const formattedData = {
        ...data,
        address: data.detail?.address || "",
        fax: data.detail?.fax || "",
        city: data.detail?.city || "",
        country: data.country?.hash_id || "",
        state: data.state?.hash_id || "",
        postal_code: data.detail?.postal_code || "",
        phone_1: data.detail?.phone1 || "",
        phone_2: data.detail?.phone2 || "",
        roles: data.roles[0]?.id,
        hide_username: data.settings?.hide_username || false,
        allow_magnifying_glass: data.settings?.allow_magnifying_glass || false,
        send_email_notifications:
          data.settings?.send_email_notifications || false,
        newsletter: data.settings?.newsletter || false,
      };

      // console.log(formattedData, "user data");
      // console.log(response.data, "user ");
      setUserInfo(formattedData);

    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().min(
      8,
      "Password must be at least 8 characters long"
    ),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const formik = useFormik({
    initialValues: {
      first_name: userInfo?.first_name || "",
      last_name: userInfo?.last_name || "",
      address: userInfo?.address || "",
      fax: userInfo?.fax || "",
      country: userInfo?.country || "",
      state: userInfo?.state || "",
      city: userInfo?.city || "",
      postal_code: userInfo?.postal_code || "",
      phone_1: userInfo?.phone_1 || "",
      phone_2: userInfo?.phone_2 || "",
      username: userInfo?.username || "",
      email: userInfo?.email || "",
      hide_username: userInfo?.hide_username || false,
      allow_magnifying_glass: userInfo?.allow_magnifying_glass || false,
      send_email_notifications: userInfo?.send_email_notifications || false,
      newsletter: userInfo?.send_email_notifications || false,
      role: userInfo?.roles,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/buyer/users/update/${hash_id}`,
          values,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        Swal.fire("Success", "Form Submitted Successfully!", "success");
        formik.resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
        Swal.fire("Error", "Error submitting form", "error");
      }
    },
  });

  useEffect(() => {
    fetchCountries();
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (formik.values.country) {
      fetchStates(formik.values.country, "stateprovience");
    } else {
      setStates([]);
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country) {
      fetchStates(formik.values.country, "states");
    } else {
      setStates([]);
    }
  }, [formik.values.country]);

  return (
    <section className="edit-info mt-5 mb-5">
      <div className="container">
        <div className="row">
          <Form onSubmit={formik.handleSubmit} className="p-4">
            <h3 className="mb-3">Edit Personal Info</h3>
            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <Form.Control
                type="text"
                name="first_name"
                placeholder="first_name"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                isInvalid={
                  formik.touched.first_name && !!formik.errors.first_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
              <Form.Control
                type="text"
                name="last_name"
                placeholder="last_name"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                isInvalid={
                  formik.touched.last_name && !!formik.errors.last_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <Form.Control
                type="text"
                name="address"
                placeholder="address"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                isInvalid={formik.touched.address && !!formik.errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.address}
              </Form.Control.Feedback>
              <Form.Control
                type="text"
                name="fax"
                placeholder="fax"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fax}
                isInvalid={formik.touched.fax && !!formik.errors.fax}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.fax}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <Form.Select
                name="country"
                onChange={formik.handleChange}
                value={formik.values.country}
                isInvalid={formik.touched.country && !!formik.errors.country}
              >
                <option value="">Country</option>
                {countries.map((country) => (
                  <option key={country.hash_id} value={country.hash_id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {formik.errors.country}
              </Form.Control.Feedback>
              <Form.Select
                name="state"
                onChange={formik.handleChange}
                value={formik.values.state}
              >
                <option>State</option>
                {statesProvienceList.length > 0 ? (
                  statesProvienceList.map((state) => (
                    <option key={state.hash_id} value={state.hash_id}>
                      {state.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No states available</option>
                )}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <Form.Control
                type="text"
                name="city"
                placeholder="city"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                isInvalid={formik.touched.city && !!formik.errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
              <Form.Control
                type="text"
                name="postal_code"
                placeholder="postal_code"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postal_code}
                isInvalid={
                  formik.touched.postal_code && !!formik.errors.postal_code
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.postal_code}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <Form.Control
                type="text"
                name="phone_1"
                placeholder="phone_1"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone_1}
                isInvalid={formik.touched.phone_1 && !!formik.errors.phone_1}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone_1}
              </Form.Control.Feedback>
              <Form.Control
                type="text"
                name="phone_2"
                placeholder="phone_2"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone_2}
                isInvalid={formik.touched.phone_2 && !!formik.errors.phone_2}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone_2}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 w-100 d-flex justify-content-between gap-2">
              <Form.Control
                type="text"
                name="username"
                placeholder="username"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                isInvalid={formik.touched.username && !!formik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
              <Form.Control
                type="text"
                name="email"
                placeholder="email"
                className="w-50"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formHideUsername">
              <Form.Check
                type="checkbox"
                label="Hide Username"
                name="hide_username"
                onChange={formik.handleChange}
                checked={formik.values.hide_username}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAllowMagnifyingGlass">
              <Form.Check
                type="checkbox"
                label="Allow Magnifying Glass"
                name="allow_magnifying_glass"
                onChange={formik.handleChange}
                checked={formik.values.allow_magnifying_glass}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSendEmailNotifications">
              <Form.Check
                type="checkbox"
                label="Send Email Notifications"
                name="send_email_notifications"
                onChange={formik.handleChange}
                checked={formik.values.send_email_notifications}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNewsletter">
              <Form.Check
                type="checkbox"
                label="Newsletter"
                name="newsletter"
                onChange={formik.handleChange}
                checked={formik.values.newsletter}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default Editinfo;
