import React, { useState, useRef, useEffect } from "react";
import "./ContactUs.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

const ContactUs = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const faqRef = useRef(null);
  const [info, setinfo] = useState();
  const url = window.location.href;

  const fetchinfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/info/contact-us`)
    setinfo(response.data.data)
    // console.log(response.data.data, "info")
  }
  useEffect(() => {
    fetchinfo()
  }, [])

  const handleQuestionClick = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
    if (index !== 3) {
      setIsFormSubmitted(false);
      setFormErrors({});
    }
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  const validateForm = (form) => {
    const errors = {};
    if (!form.subject.value) errors.subject = "Subject is required.";
    if (!form.phone.value || !/^\d{10}$/.test(form.phone.value))
      errors.phone = "Phone number must be 10 digits.";
    if (!form.email.value) errors.email = "Email is required.";
    if (!form.firstName.value) errors.firstName = "First Name is required.";
    if (!form.lastName.value) errors.lastName = "Last Name is required.";
    if (!form.message.value) errors.message = "Message is required.";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const errors = validateForm(form);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setIsFormSubmitted(true);
    form.reset();
    setFormErrors({});
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const contact = () => {
    navigate("/home/sellstuff");
  };

  return (
    <>
      <Helmet>
        <title>{`${info?.meta_title || info?.name}`}</title>
        <meta
          name="description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:title"
          content={`${info?.meta_title || info?.name}`}
        />
        <meta
          property="og:description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:url"
          content={url}
        />
        <link
          rel="canonical"
          href={url}
        />
      </Helmet>

      <div className="contact-us container pt-5">
        <div className="row">
          <p dangerouslySetInnerHTML={{ __html: info?.description || "" }} />
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p> */}

          {/* <h3 className="text-start px-3 pt-3">
           Are You An Auctioneer?
          </h3> */}
          {/* <p>
            Auctions is the integrated online auction platform and bidding
            solution for Auction Flex 360. If you are an auctioneer or auction
            company inquiring about the auction platform, contact Auction Flex
            360.
          </p>
          <div className="text-start px-3 mb-3">
            <button className="contact-us-form-contact">
              <a
                className="text-decoration-none text-white"
                href="https://www.auctionflex.com/contact-us.htm"
              >
                Contact Auction Flex 360
              </a>
            </button>
          </div>
          <h3 className="px-3 text-start">
            <b>Are You A Auctions Customer?</b>
          </h3>
          <p className="text-start px-3 pt-3">
            If you’re interested in consigning items for sale through Auctions,
            click the button below to get started.
          </p>
          <div className="text-start px-3">
            <button className="contact-us-form-contact" onClick={contact}>
              Start Selling
            </button>
          </div>
          <p className="text-start px-3 pt-3">
            If you are an auctioneer interested in selling items through the
            Auctions platform, click the button below.
          </p>
          <div className="text-start px-3 mb-4">
            <button className="contact-us-form-contact" onClick={contact}>
              Start Selling (auction)
            </button>
          </div>
          <div className="text-start information">
            <p className="pt-3 text-start">
              If you need more information about Auctions,
            </p>
            <p className="pb-3">
              Visit our Auctions Help and Auctions FAQ pages.
            </p>
          </div>
          <div className="text-start information">
            <p className="pt-3 text-start mb-0">
              For all other Auctions inquiries, please select an option below:
            </p>
          </div>
          <div
            className="faq-section faq-ques-ans p-4 rounded shadow-sm"
            ref={faqRef}
          >
            <div
              className={`faq-item ${activeQuestion === 1 ? "active" : ""}`}
              onClick={() => handleQuestionClick(1)}
            >
              <h4 className="faq-question">
                Contact Auctioneer
                <i
                  className={`fa ${
                    activeQuestion === 1 ? "fa-chevron-up" : "fa-chevron-down"
                  } faq-arrow`}
                />
              </h4>
              {activeQuestion === 1 && (
                <div className="faq-answer">
                  <p>
                    To contact or find information about an auctioneer on
                    auctionslive.com:
                  </p>
                  <p>
                    1. Click to open a lot in your watch list or anywhere on
                    auctionslive.com and then click the name under Auctioneer
                    Information.
                  </p>
                  
                  <p className="pt-3">
                    2. If you are viewing an auction page, click the
                    auctioneer’s name above the auction description.
                  </p>
                 
                  <p className="pt-3">
                    3. You will find the auctioneer’s complete contact
                    information on the page that displays.
                  </p>
                  
                </div>
              )}
            </div>
            <div
              className={`faq-item ${activeQuestion === 2 ? "active" : ""}`}
              onClick={() => handleQuestionClick(2)}
            >
              <h4 className="faq-question">
                Registering To Bid
                <i
                  className={`fa ${
                    activeQuestion === 2 ? "fa-chevron-up" : "fa-chevron-down"
                  } faq-arrow`}
                />
              </h4>
              {activeQuestion === 2 && (
                <div className="faq-answer">
                  <p>
                    Because each auctioneer and auction company has different
                    terms and conditions for their sales, you must register
                    separately for each auction you want to bid on rather than
                    registering a single time to bid on all Auctions auctions.
                    You can examine an auction’s lots and watch its progress
                    without registering for the sale, but if you wish to bid,
                    you will be required to register for the auction and accept
                    its terms and conditions. Note that you will need to be
                    logged into your Auctions account before bidding on an
                    auction, or create one if you haven't already.
                  </p>
                  <ul>
                    <li className="mb-2">Click/tap the button below.</li>
                    <li className="mb-2">
                      Click/tap Register Here, and then on the Registration page
                      enter your contact information.
                    </li>
                    <li className="mb-2">Choose a username and password.</li>
                    <li className="mb-2">Click/tap Create New Account.</li>
                  </ul>
                  <button className="btn bg-primary">
                    <a
                      className="text-decoration-none text-white"
                      href="/authentication/sign-in"
                    >
                      Register to Bid
                    </a>
                  </button>
                </div>
              )}
            </div>
            <div
              className={`faq-item ${activeQuestion === 3 ? "active" : ""}`}
              onClick={() => handleQuestionClick(3)}
            >
              <h4 className="faq-question">
                General Website Feedback
                <i
                  className={`fa ${
                    activeQuestion === 3 ? "fa-chevron-up" : "fa-chevron-down"
                  } faq-arrow`}
                />
              </h4>
              {activeQuestion === 3 && (
                <div className="faq-answer">
                  {!isFormSubmitted ? (
                    <form
                      className="feedback-form"
                      onSubmit={handleSubmit}
                      onClick={handleFormClick}
                    >
                      <div className="form-group contact-form-fileds">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Subject *"
                        />
                        {formErrors.subject && (
                          <div className="form-error">{formErrors.subject}</div>
                        )}
                      </div>
                      <div className="form-group contact-form-fileds">
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Phone *"
                          pattern="[0-9]{10}"
                          title="Phone number must be 10 digits"
                        />
                        {formErrors.phone && (
                          <div className="form-error">{formErrors.phone}</div>
                        )}
                      </div>
                      <div className="form-group contact-form-fileds">
                        <input
                          type="text"
                          name="address1"
                          className="form-control"
                          placeholder="Physical Address (no PO Boxes)"
                        />
                      </div>
                      <div className="form-group contact-form-fileds ">
                        <input
                          type="text"
                          name="address2"
                          className="form-control"
                          placeholder="Address Line 2"
                        />
                      </div>
                      <div className="form-group contact-form-fileds">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email *"
                        />
                        {formErrors.email && (
                          <div className="form-error">{formErrors.email}</div>
                        )}
                      </div>
                      <div className="form-group contact-form-fileds">
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="First Name *"
                        />
                        {formErrors.firstName && (
                          <div className="form-error">
                            {formErrors.firstName}
                          </div>
                        )}
                      </div>
                      <div className="form-group contact-form-fileds">
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Last Name *"
                        />
                        {formErrors.lastName && (
                          <div className="form-error">
                            {formErrors.lastName}
                          </div>
                        )}
                      </div>
                      <div className="form-group contact-form-fileds">
                        <textarea
                          name="message"
                          className="form-control"
                          placeholder="Message *"
                          rows="4"
                        ></textarea>
                        {formErrors.message && (
                          <div className="form-error">{formErrors.message}</div>
                        )}
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </form>
                  ) : (
                    <p className="feedback-confirmation">
                      Thank you for your feedback!
                    </p>
                  )}
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ContactUs;
