import React, { useState } from "react";

const faqData = [
  {
    question: "What are Auctions and Auction Flex 360?",
    answer: `Auction is a robust online auction platform supporting multiple types of bidding for online-only and in-person auctions. Auctions is the integrated web service and bidding solution for Auction Flex 360, which is the leading auction software for live auctions. Auctions is unique in that it is both a private-label solution and a portal. Auction Flex 360 includes cataloging, clerking, cashiering, accounting, mailing list management, inventory management, multi-parcel, and more. Auction Flex 360 also provides a seamless solution for auction companies to upload auctions to their websites and accept internet absentee bids, conduct internet-only auctions, or offer webcast live internet bidding.`,
  },
  {
    question: "What can you sell on Auctions?",
    answer: `You can sell just about anything on Auctions. Cars, boats, motorcycles, toys, collectibles, sports memorabilia, artwork, antiques, guns, furniture, home décor, jewelry, coins, real estate, and personal property are just a few of the many types of items you can sell. Click Shop By Category at the top of the page to view all of the available categories.`,
  },
  {
    question:
      "What's the difference between webcast live bidding, internet-only bidding, and internet absentee bidding?",
    answer: `
            The Auctionslive platform supports three different types of bidding:
            <ul>
                <li class="faq-list-item"><strong>Webcast Live Bidding</strong> – allows internet bidders to bid live against floor bidders.</li>
                <li class="faq-list-item"><strong>Internet-Only Bidding</strong> – for auctions conducted completely online.</li>
                <li class="faq-list-item"><strong>Internet Absentee Bidding</strong> – used for pre-bidding prior to a live, in-person auction.</li>
            </ul>
        `,
  },
  {
    question: "How do auctions get uploaded and downloaded?",
    answer:
      "Auction Flex 360 and Auctionslive were built from the ground up to work together seamlessly. To upload an auction, simply build the auction using the Auction Flex 360 auction management software, click upload, and set a few options. Auctions will show both catalogued auctions and non-cataloged auctions. Images can be included for all lots, some lots, or no lots, or there can simply be preview images for the auction. It's up to the auctioneer or auction company as to how much to include.",
  },
  {
    question: "What are featured auctions and how do they work?",
    answer:
      "Featured auctions provide auctioneers with an excellent opportunity to reach bidders quickly through the paid promotion of specific auctions. The top three spots on the auctionslive.com home page and some state portals are auctioned off weekly (every Monday by 1 p.m. Eastern Time). These featured spots are also included at the top of the weekly email blasts to Auctions customers, providing extra exposure to those auctions. Click Here to bid on featured auctions.",
  },
  {
    question: "What are hot auctions?",
    answer:
      "Hot Auctions are the auctions that have received the most views. On the auctionslive homepage under Hot Auctions, click View All to access a list of all current Hot Auctions or click Browse By on the menu bar and select Hot Auctions.",
  },
  {
    question: "How do bidders add lots to a watch list?",
    answer:
      "Once users are logged on to auctionslive.com proper or a auctionslive auctioneer/auction company’s website, they can select an auction, view the lot list, and click the Watch (black star) button on a specific lot to add it to their personal watch list.<br/> <br/>When an item is added to a watch list, a pop-up box provides an opportunity to add personal notes for the specific item. To remove an item from the watch list, users click the black X button next to the lot.",
  },
  {
    question: "How do bidders place bids?",
    answer:
      "Users who have logged on to auctionslive.com proper, a auctionslive state portal, or a private-label site and who have registered with a credit card (based on optional auctioneer credit card retention policies) can then place bids on an item. When viewing a lot list for an auction where bidding is open, the bidder can click the Bid (blue plus sign) button to place a bid on that item.",
  },
  {
    question: "What are private-label auctions?",
    answer:
      "Auction’s private-label offering gives auction companies an exclusive site for building their brand plus non-branded exposure through auctionslive.com proper and any corresponding Auctions state category portal. A fully searchable auction calendar is provided for both the auction company’s cataloged and non-cataloged auctions. Auctioneers can upload all of their events to their auction calendar and will only be charged for the auctions that have bidding enabled.",
  },
];

const FAQComponent = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div className="container my-4">
        <div className="row">
          <h2 className="px-3 mb-4">Frequently Asked Questions </h2>

          {faqData.map((faq, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ddd",
                marginBottom: "10px",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: "15px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => handleToggle(index)}
                className="m-0 "
              >
                {faq.question}
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                <div
                  style={{ padding: "15px", backgroundColor: "#fff" }}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
