import React, { useEffect, useState } from "react";
import axios from "axios";
import { RingLoader } from "react-spinners";
import Category12 from "../../../assets/images/Category3.png";
import { Link, useNavigate } from "react-router-dom";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [chunkedCategories, setChunkedCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const processCategories = (cats) => {
          return cats.map((cat) => {
            const media =
              cat.media && cat.media.length > 0
                ? `${process.env.REACT_APP_BASE_IMAGE_URL}/storage/${cat.media[0].file_path}`
                : Category12;

            return {
              ...cat,
              image: media,
              descendants: cat.descendants
                ? processCategories(cat.descendants)
                : [],
            };
          });
        };
        const processedCategories = processCategories(
          response.data.data.filter((category) => category.category_type === "main")
        );
        setCategories(processedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);


  const navigate = useNavigate();
  const CatgoryAll = () => {
    navigate("/auction-categories");
  };
  return (
    <div className="category pt-3 mt-5">
      <div className="container">
        <div className="row align-items-center mt-3">
          <div className="col-md-6 col-sm-6">
            <h4 className="auctions-category mt-2 text-uppercase">
              Find auctions by category
            </h4>
          </div>
          <div className="col-md-6 col-sm-6 text-md-right d-flex justify-content-md-end mt- mt-md-0">
            <button className="view-all-btn" onClick={CatgoryAll}>
              View all
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loader-container d-flex justify-content-center mt-5">
            <RingLoader color={"#123abc"} loading={loading} size={150} />
          </div>
        ) : (
          <div className="row mt-3">
            {categories && categories.map((category, index) => (
              <div
                className="col-md-3 col-lg-2 col-6 mb-2 auctions-card-images"
                key={`${category.hash_id}-${index}`}
              >
                <Link
                  to="/auction-categories"
                  className="text-decoration-none linking"
                >
                  <div className="card category-card h-100">
                    <div className="card-body category-cards">
                      <div className="category-images flex items-center justify-center w-full">
                        <img
                          src={category.image}
                          alt={category.name}
                          className="img"
                          width={100}
                          height={100}
                          loading="lazy"
                        />
                      </div>
                      <h6 className="text-center category-text-heading mt-3 w-full mb-0 ">
                        {category.name}
                      </h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
