import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const FormDateTimeInput = ({
  id,
  label,
  placeholder,
  type,
  variant,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  InputLabelProps,
  sx,
}) => {
  return (
    <TextField
      id={id}
      label={label}
      placeholder={placeholder}
      type={type}
      variant={variant}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputLabelProps={InputLabelProps}
      sx={sx}
    />
  );
};

FormDateTimeInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  InputLabelProps: PropTypes.object,
  sx: PropTypes.object,
};

export default FormDateTimeInput;
