import React, { useEffect, useState } from "react";
import axios from "axios";
import Category12 from "../../../assets/images/Category3.png";
import { useNavigate, Link } from "react-router-dom";
import LotLoader from "../../pages/Category/Common/LotLoader";
import "./AuctionCategories.css";
import Slider from "react-slick";

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 6;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const processCategories = (cats) => {
          return cats.map((cat) => {
            const media =
              cat.media.length > 0
                ? `${process.env.REACT_APP_BASE_IMAGE_URL}/storage/${cat.media[0].file_path}`
                : Category12;
            return {
              ...cat,
              image: media,
              descendants: processCategories(cat.descendants),
            };
          });
        };
        const processedCategories = processCategories(response.data.data);
        setCategories(processedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate("/auction-categories");
  };
  const displayedCategories = categories.slice(0, itemsPerPage);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
  };

  return (
    <div className="category pt-4">
      <div className="container">
        {loading ? (
          <LotLoader />
        ) : (
          <div className="d-flex justify-content-center  ">
            <div className="row  w-full category-scroll">
              <Slider {...settings}>
                {displayedCategories.map((category, index) => (
                  <div className="mb-2 auctions-card-images" key={index}>
                    <Link to="/lots" className="text-decoration-none">
                      <div className="card category-card">
                        <div className="card-body category-cards">
                          <div className="category-images w-full flex items-center justify-center">
                            <img
                              src={category.image || Category12}
                              alt={category.name}
                              className="img max-w-[80px] max-h-[80px]"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
                          <h6 className="text-center category-text-heading mt-3 w-full">
                            {category.name}
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
