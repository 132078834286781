import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [role, setRole] = useState(
    localStorage.getItem("roles")
      ? localStorage.getItem("roles").split(",")[0]
      : null
  );

  const login = (roles) => {
    const rolesArray = roles.split(",");
    setRole(rolesArray[0]);
  };

  const logout = () => {
    setRole(null);
    localStorage.clear();
  };
  
  return (
    <AuthContext.Provider value={{ role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
export function useAuth() {
  return useContext(AuthContext);
}
