import React from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
const LoginModal = ({ isOpen, onClose }) => {
  const formik = useFormik({
    initialValues: {
      email_or_username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email_or_username: Yup.string().required("Email or Username is required"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        ),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/login`,
          values
        );
        if (response.status === 200) {
          const userData = response.data["0"];
          const accessToken = response.data.access_token;
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("email", userData.email);
          localStorage.setItem("hash_id", userData.hash_id);
          localStorage.setItem("username", userData.username);
          const roles = userData.roles.map((role) => role.name);
          localStorage.setItem("roles", roles.join(","));
          toast.success("Login successful!");
          onClose();
        } else if (
          response.data.status === "error" &&
          response.data.message === "invalid-credentials"
        ) {
          toast.error("Invalid credentials, please try again.");
        } else {
          console.error("Login failed:", response.data);
        }
      } catch (error) {
        toast.error("An error occurred during login.");
        console.error("Login error:", error);
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Login Modal"
      className="login-modal"
      overlayClassName="login-modal-overlay"
    >
      <h2>Login</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label>Email or Username</label>
          <input
            type="text"
            name="email_or_username"
            value={formik.values.email_or_username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${
              formik.touched.email_or_username &&
              formik.errors.email_or_username
                ? "is-invalid"
                : ""
            }`}
          />
          {formik.touched.email_or_username &&
            formik.errors.email_or_username && (
              <div className="invalid-feedback">
                {formik.errors.email_or_username}
              </div>
            )}
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`form-control ${
              formik.touched.password && formik.errors.password
                ? "is-invalid"
                : ""
            }`}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="invalid-feedback">{formik.errors.password}</div>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default LoginModal;
