import React from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const FormSelect = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  error,
  children,
  sx,
}) => {
  return (
    <>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        sx={sx}
      >
        {children}
      </Select>
    </>
  );
};

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default FormSelect;
