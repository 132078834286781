import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const pageRange = 5;

  const startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
  const endPage = Math.min(totalPages, startPage + pageRange - 1);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {currentPage > 1 && (
        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
      )}
      {currentPage > 2 && <Pagination.Ellipsis />}
      {[...Array(endPage - startPage + 1).keys()].map((num) => (
        <Pagination.Item
          key={startPage + num}
          active={startPage + num === currentPage}
          onClick={() => handlePageChange(startPage + num)}
        >
          {startPage + num}
        </Pagination.Item>
      ))}
      {endPage < totalPages - 1 && <Pagination.Ellipsis />}
      {totalPages > 1 && (
        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      )}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationComponent;
