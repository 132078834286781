import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import Card from "@mui/material/Card";
import { Box, Switch, TextField, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AuctionLogin.css";
import ForgotPasswordModal from "../../Login/ForgotPasswordModal";

function AuctionLogin() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [access_token, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );
  const [openForgot, setForgotOpen] = useState(false);
  const [params, setParams] = useState({
    id: null,
    hash: null,
  });
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlParam = searchParams.get("url");
    let id = null,
      hash = null;
    if (urlParam) {
      const parts = urlParam.split("/");
      id = parts[0];
      hash = parts[1];
    }
    setParams({
      id,
      hash,
    });
    if (id && hash) {
      verifyEmail(id, hash);
    }
  }, [params]);

  const verifyEmail = async (id, hash) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/verify-emails/${id}/${hash}`;
    // console.log(apiUrl)
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("API request failed");
      }
      const data = await response.json();
      setApiResponse(data);
    } catch (err) {
      setError(err.message);
    }
  };

  // Check for remember me credentials in sessionStorage
  useEffect(() => {
    const savedCredentials = JSON.parse(
      sessionStorage.getItem("rememberMeCredentials")
    );
    if (savedCredentials) {
      formik.setValues(savedCredentials);
      setRememberMe(true);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("email");
    localStorage.removeItem("hash_id");
    localStorage.removeItem("username");
    localStorage.removeItem("roles");
    localStorage.removeItem("user_id");
    sessionStorage.removeItem("rememberMeCredentials");
    navigate("/authentication/sign-in");
  };

  const formik = useFormik({
    initialValues: {
      email_or_username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email_or_username: Yup.string().required("Required"),
      password: Yup.string()
        .min(4, "Password must be at least 4 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/login`,
          values
        );
        if (response.status === 200) {
          // console.log(response, "login")
          const userData = response.data["0"];
          const accessToken = response.data.access_token;
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("email", userData.email);
          localStorage.setItem("hash_id", userData.hash_id);
          localStorage.setItem("username", userData.username);
          localStorage.setItem("user_id", userData.detail.user_id);
          const roles = userData.roles.map((role) => role.name);
          localStorage.setItem("roles", roles.join(","));
          setAccessToken(accessToken);
          login(roles.join(","));
          if (rememberMe) {
            sessionStorage.setItem(
              "rememberMeCredentials",
              JSON.stringify(values)
            );
          } else {
            sessionStorage.removeItem("rememberMeCredentials");
          }
          if (roles.includes("admin")) {
            navigate("/super/admin/dashboard");
          } else if (roles.includes("auctioneer")) {
            navigate("/auctioner/dashboard");
          } else if (roles.includes("buyer")) {
            navigate("/user/dashboard");
          } else {
            navigate("/");
          }
          toast.success("Login successful!");
          setTimeout(logout, 86400000);
        } else if (
          response.data.status === "error" &&
          response.data.message === "invalid-credentials"
        ) {
          toast.error("Invalid credentials, please try again.");
        } else {
          console.error("Login failed:", response.data);
          toast.error("Login failed. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred during login.");
        console.error("Login error:", error);
      }
    },
  });

  // Handle Remember Me checkbox toggle
  const handleSetRememberMe = () => setRememberMe((prevState) => !prevState);

  if (access_token) {
    navigate("/");
    return null;
  }

  const handleEdit = () => {
    setForgotOpen(true);
  };

  const handleCloseforgot = () => {
    setForgotOpen(false);
  };

  return (
    <>
      <Card sx={{ maxWidth: 400, mx: "auto", mt: 8, p: 3 }}>
        <ToastContainer />
        <Box sx={{ mb: 3, textAlign: "center" }}>
          <Typography variant="h4" color="textPrimary" gutterBottom>
            Sign in
          </Typography>
        </Box>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Email or Username"
              name="email_or_username"
              value={formik.values.email_or_username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.email_or_username &&
                Boolean(formik.errors.email_or_username)
              }
              helperText={
                formik.touched.email_or_username &&
                formik.errors.email_or_username
              }
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ ml: 1, cursor: "pointer" }}
              >
                Remember me
              </Typography>
            </Box>
            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Typography color="textSecondary" onClick={() => handleEdit()}>
                Forgot password?
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <div className="text-center">
              <Button
                variant="contained"
                className="sign-in-auctioner"
                color="primary"
                fullWidth
                type="submit"
              >
                Sign in
              </Button>
            </div>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary">
              Don&apos;t have an account?{" "}
              <Link to="/authentication/sign-up">
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                >
                  Sign up
                </Typography>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Card>
      <ForgotPasswordModal show={openForgot} onHide={handleCloseforgot} />
    </>
  );
}
export default AuctionLogin;
