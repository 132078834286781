import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AuctionState.css";
import { RingLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ITEMS_PER_PAGE = 40;
const PAGE_BUTTONS_DISPLAYED = 5;

const StateAuctions = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [statesOrProvinces, setStatesOrProvinces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingStates, setLoadingStates] = useState(true);
  const [countrySearch, setCountrySearch] = useState("");
  const [stateSearch, setStateSearch] = useState("");

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/countries`,
          {
            headers: {
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        const fetchedCountries = response.data.data;
        setCountries(fetchedCountries);
        setSelectedCountry(fetchedCountries[0]?.name || null);
        if (fetchedCountries[0]?.hash_id) {
          fetchStatesOrProvinces(fetchedCountries[0]?.hash_id);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchCountries();
  }, []);
  const navigate = useNavigate();
  const fetchStatesOrProvinces = async (countryId) => {
    setLoadingStates(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${countryId}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const fetchedStatesOrProvinces = response.data.data;
      setStatesOrProvinces(fetchedStatesOrProvinces);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching states or provinces:", error);
    } finally {
      setLoadingStates(false);
    }
  };

  const handleToggle = (country) => {
    setSelectedCountry(country.name);
    fetchStatesOrProvinces(country.hash_id);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(countrySearch.toLowerCase())
  );

  const filteredStatesOrProvinces = statesOrProvinces.filter(
    (stateOrProvince) =>
      stateOrProvince.name.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = filteredStatesOrProvinces.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(
    filteredStatesOrProvinces.length / ITEMS_PER_PAGE
  );

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const startPage = Math.max(
    1,
    currentPage - Math.floor(PAGE_BUTTONS_DISPLAYED / 2)
  );
  const endPage = Math.min(totalPages, startPage + PAGE_BUTTONS_DISPLAYED - 1);

  const displayedPageNumbers = pageNumbers.slice(startPage - 1, endPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const mapHandler = (event) => {};

  const statesFilling = () => {
    return {
      NJ: {
        fill: "navy",
        clickHandler: () => alert("Custom callback for the NJ state"),
      },
      NY: {
        fill: "#CC0000",
      },
    };
  };

  const provincesFilling = () => {
    return {
      ON: {
        fill: "blue",
        clickHandler: () => alert("Custom callback for Ontario"),
      },
      BC: {
        fill: "green",
      },
    };
  };

  const handleStateClick = (stateId) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      navigate(`/lots?hash_id=${stateId}`);
    } else {
      // alert('Please login to view this content.');
      toast.error("Please login to view this content.");
    }
  };

  return (
    <div className="state-auction">
      <div className="container-fluid">
        <div className="row auction-home">
          <p className="text-start pt-2 px-2">
            HOME
            <span> &gt; </span>
            <span>AUCTIONS BY STATE/PROVINCE</span>
          </p>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-5">
          <h1 className="state-online-auction">
            {selectedCountry === "United States"
              ? "VIEW LIVE AND ONLINE AUCTIONS IN YOUR STATE"
              : selectedCountry === "Canada"
              ? "VIEW LIVE AND ONLINE AUCTIONS IN YOUR PROVINCE"
              : ""}
          </h1>
        </div>
        <hr />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <p>
              {selectedCountry === "United States" ? (
                <>
                  Select the state from the below or
                  <br /> <b>Search state </b>
                </>
              ) : selectedCountry === "Canada" ? (
                <>
                  Select the state from the below or
                  <br />
                  <b>Search state</b>
                </>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="col-md-8 ">
            <div className="map-btns">
              <input
                type="text"
                placeholder="Search states..."
                value={stateSearch}
                onChange={(e) => setStateSearch(e.target.value)}
                className="search-bar state-searchbar"
              />
              {loadingCountries ? (
                <div className="spinner-container">
                  <RingLoader color="#007bff" />
                </div>
              ) : (
                filteredCountries.map((country, index) => (
                  <button
                    key={index}
                    className={`toggle-btn  ${
                      selectedCountry === country.name ? "active" : ""
                    }`}
                    onClick={() => handleToggle(country)}
                  >
                    {country.name}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container map-container">
        <div className="row pt-4 flex ">
          {loadingStates ? (
            <div className="spinner-container">
              <RingLoader color="#007bff" />
            </div>
          ) : (
            currentItems.map((item, index) => (
              <div
                className=" states-hover-bg col-md-3 border flex items-center justify-center hover:bg-[#2695ff]"
                key={index}
              >
                <p
                  className="state-province-name m-0 py-2 hover:text-[#fff] "
                  onClick={() => handleStateClick(item.hash_id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.name}
                </p>
              </div>
            ))
          )}
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="pagination">
              <button
                className="pagination-icon"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                <i className="fas fa-chevron-left"></i>
              </button>
              {startPage > 1 && (
                <>
                  <button
                    className="pagination-btn"
                    onClick={() => handlePageClick(1)}
                  >
                    1
                  </button>
                  {startPage > 2 && (
                    <span className="pagination-ellipsis">...</span>
                  )}
                </>
              )}
              {displayedPageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`pagination-btn ${
                    pageNumber === currentPage ? "active" : ""
                  }`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              {endPage < totalPages && (
                <>
                  {endPage < totalPages - 1 && (
                    <span className="pagination-ellipsis">...</span>
                  )}
                  <button
                    className="pagination-btn"
                    onClick={() => handlePageClick(totalPages)}
                  >
                    {totalPages}
                  </button>
                </>
              )}
              <button
                className="pagination-icon"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StateAuctions;
