import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spinner, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BiddType.css";

const BiddingTypes = () => {
  const [biddingTypes, setBiddingTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBiddingTypes = async () => {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("Access token not found.");
        setLoading(false);
        return;
      }
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/bidding-types`,
          { headers }
        );
        if (response.data.status === "success") {
          setBiddingTypes(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("An error occurred while fetching bidding types.");
      } finally {
        setLoading(false);
      }
    };
    fetchBiddingTypes();
  }, []);

  if (loading)
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className="mt-3">Loading...</p>
      </div>
    );
  if (error)
    return (
      <div className="text-center mt-5">
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  return (
    <div className="container p-5">
      <h2>Bidding Types</h2>
      {biddingTypes.length === 0 ? (
        <div className="text-center mt-5">
          <Alert variant="info">No data found</Alert>
        </div>
      ) : (
        <Table striped bordered hover className="bidding-table mt-5 mb-5">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {biddingTypes.map((biddingType) => (
              <tr key={biddingType?.hash_id}>
                <td>{biddingType?.name}</td>
                <td className="text-wrap">{biddingType?.description}</td>
                <td>{biddingType?.is_active === 1 ? "Enable" : "Disable"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
export default BiddingTypes;
