import React, { useState } from "react";
import SideBar from "./SideBar";
import MainDiv from "./MainDiv";
import "./CompanySearch.css";

const CompanySearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("All location");

  const handleSearch = (term) => {
    setSearchTerm(term);
    setSelectedLocation("All location");
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
    setSearchTerm("");
  };

  return (
    <div className="companySearch">
      <div className="container-fluid">
        <div className="row mt-3">
          <h1 className="search-auctioneers">SEARCH AUCTIONEERS NEAR ME</h1>
        </div>
        <div className="row">
          <div className="col-md-3">
            <SideBar
              onSearch={handleSearch}
              onLocationChange={handleLocationChange}
            />
          </div>
          <div className="col-md-9">
            <MainDiv
              searchTerm={searchTerm}
              selectedLocation={selectedLocation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySearch;
