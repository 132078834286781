import React, { useEffect, useState } from "react";
import "./Bloglist.css";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { format } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Addblog from "./Addblog";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Editblog from "./Editblog";

function Bloglist() {
  const [modalShow, setModalShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editid, setEditid] = useState(null);

  const fetchBloglist = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/posts/list`,
        apiHeaders
      );
      const formattedData = response.data.data.map((item) => ({
        ...item,
        created_at: format(new Date(item.created_at), "MMMM dd, yyyy, h:mm a"),
      }));
      setData(formattedData || []);
      // console.log("blog list", response.data.data)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBloglist();
  }, []);

  const handleAddBlog = () => {
    setModalShow(true);
  };

  const columns = [
    { name: "id", label: "ID" },
    { name: "name", label: "Name" },
    {
      name: "description",
      label: "DESCREPTION",
      options: {
        customBodyRender: (value) => {
          if (!value) {
            return "";
          }
          const words = value.split(" ");
          const truncatedDescription =
            words.length > 10 ? words.slice(0, 10).join(" ") + "..." : value;
          return (
            <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
          );
        },
      },
    },
    { name: "created_at", label: "Created At", options: { sort: true } },
    {
      name: "action",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <button
                className="btn btn-sm"
                onClick={() => handleEditBlog(tableMeta.rowData[0])}
              >
                <BorderColorIcon style={{ color: "green" }} />
              </button>
              <button
                className="btn btn-sm"
                onClick={() => handleDeleteBlog(tableMeta.rowData[0])}
              >
                <DeleteIcon style={{ color: "red" }} />
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
  };

  const handleEditBlog = (id) => {
    setEditid(id);
    setEditShow(true);
    // console.log(`Edit blog with ID: ${id}`);
  };

  const handleDeleteBlog = async (id) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      setLoading(false);
      return;
    }
    const apiHeaders = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",
      },
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (!result.isConfirmed) {
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/posts/delete/${id}`,
        apiHeaders
      );

      Swal.fire({
        title: "Deleted!",
        text: "Your blog post has been deleted.",
        icon: "success",
      });
      fetchBloglist();
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Error deleting blog");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <section className="admin-subscribe">
        <div className="container">
          <div className="row mt-5 px-3">
            <div className="blog-add d-flex justify-content-between align-items-center mb-3">
              <h2>Blog List</h2>
              <button
                className="btn btn-primary add-blog-btn"
                onClick={handleAddBlog}
              >
                <AddIcon />
                ADD BLOG
              </button>
            </div>

            {loading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <MUIDataTable
                data={data.length ? data : []}
                columns={columns}
                options={options}
              />
            )}
          </div>
        </div>
      </section>
      <Addblog
        open={modalShow}
        onClose={() => setModalShow(false)}
        fetchBloglist={fetchBloglist}
      />
      <Editblog
        open={editShow}
        onClose={() => setEditShow(false)}
        id={editid}
        fetchBloglist={fetchBloglist}
      />
    </>
  );
}

export default Bloglist;
