import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({
  showModal,
  handleClose,
  handleConfirm,
  actionType,
  item,
}) => (
  <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Action</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-black">
      Are you sure you want to{" "}
      {actionType === "country" ? "disable/enable" : "disable/enable"}{" "}
      {item ? item.name : ""}?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmationModal;
