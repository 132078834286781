import React, { useEffect, useState } from "react";
import "./AboutHibid.css";
import { Helmet } from "react-helmet";
import axios from "axios";

const AboutHibid = () => {
  window.scroll(0, 0);
  const [info, setinfo] = useState();
const url = window.location.href;

  const fetchinfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/info/about-us`)
    setinfo(response.data.data)
    // console.log(response.data.data, "info")
  }
  useEffect(() => {
    fetchinfo()
  }, [])

  return (
    <>
    <Helmet>
      <title>{`${info?.meta_title || info?.name}`}</title>
        <meta
          name="description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:title"
          content={`${info?.meta_title || info?.name}`}
        />
        <meta
          property="og:description"
          content={`${info?.meta_description || info?.name}`}
        />
        <meta
          property="og:url"
          content={url}
        />
        <link
          rel="canonical"
          href={url}
        />
      </Helmet>
    <div className="container abouthibibis">
      <div className="row ">
      <p dangerouslySetInnerHTML={{ __html: info?.description || "" }} />
        {/* <div className="col-md-12">
          <h1 className="text-start px-3 pt-4" style={{ color: "#2695ff", fontSize: "28px"}}>
            About Auctionslive.net
          </h1>
          <p className="about-hibis-text px-3 pt-3">
            AuctionsLive.net is the premier auction platform for selling a huge
            variety of items. Everything from real estate, cars, and fine
            jewelry to collectibles, sports memorabilia, and personal property
            is open for bidding on auctionslive.net at any given time.
          </p>
          <p className="about-hibis-text px-3 pt-3">
            Thousands of auction companies worldwide use our Proxibid type
            Auctions to conduct live webcast, online-only, and absentee bidding
            auctions.
          </p>
          <h5 className="pt-3 text-start px-3">
            {" "}
            <b>Ideal For Local & Online Auctions</b>{" "}
          </h5>
          <p className="about-hibis-text px-3">
            AuctionsLive.net supports multiple types of online bidding, giving
            auctioneers the options they need to host auctions large and small
            either in-person or online. This gives bidders from around the world
            immediate access to huge estate sales, Government Auctions and
            liquidations they might not otherwise be able to attend, and the
            ability to place bids at their convenience from any location.
          </p>
          <h5 className="pt-3 text-start px-3">
            {" "}
            <b>Auctions For Everyone</b>{" "}
          </h5>
          <p className="about-hibis-text px-3">
            Bidders can find auctions and lots quickly by simply using the
            auctionslive.net search bar or browsing the hundreds of categories
            from the menu on the homepage.
          </p>

          <h5 className="pt-3 text-start px-3 pt-3">
            <b>
              Selling on Auctionslive.net can be a good way to reach a large
              audience and grow your business. Here are some things to know
              about selling on Auctionslive.net:{" "}
            </b>{" "}
          </h5>
          <li>
            <strong>Benefits</strong>{" "}
          </li>
          <ol>
            <li>
              Auctionslive.net offers access to millions of buyers worldwide, a
              user-friendly interface, and a strong reputation.
            </li>
            <li>
              You can sell almost anything on Auctionslive.net, and you only pay
              a final value fee when an item sells.
            </li>
          </ol>
          <li>
            <b>Getting started </b>
          </li>
          <p className="about-hibis-text px-3 pt-3">
            To start selling on Auctionslive.net, you can:{" "}
          </p>
          <ol>
            <li>
              Register for an Auctionslive.net account and complete verification
              . Must be an auctioneer to sell on auctionslive.net.
            </li>
            <li>Create a listing for the item you want to sell</li>
            <li>
              Set up shipping details, including your shipping terms and the
              regions you ship to
            </li>
          </ol>
          <li>Fees</li>
          <ol>
            <li>
              You'll pay a final value fee, or selling fee, which is a
              percentage of an item's total sale price. Fees can vary by
              category.{" "}
            </li>
          </ol>

          <p className="about-hibis-text px-3">
            Contact auctionslive.net for a list of pricing. By far the best
            place to market your items.
          </p>
          <h5 className="pt-3 text-start px-3 ">
            {" "}
            <b>
              Visit our{" "}
              <a
                href="/info/faq"
                className="text-decoration-none"
                style={{ color: "#2695ff" }}
              >
                FAQ Page{" "}
              </a>
              for more information
            </b>{" "}
          </h5>
          <div className="about-hibis-text px-3 pt-2 pb-4">
            <button className="about-hibis-text-btn btn btn-primary">
              <a
                className="text-decoration-none text-white"
                href="/authentication/sign-in"
              >
                Start Bidding Today
              </a>
            </button>
          </div>
          <h5 className="pt-3 text-start px-3 ">
            <b>Designed To Make Your Auctions Run Faster And Smoother</b>
          </h5>
          <h5 className="pt-3 text-start px-3 ">
            <b>Lower fees means More money for your business .</b>
          </h5>
          <div className="about-hibis-text px-3 pt-2 pb-5">
            <button className="about-hibis-text-btn btn btn-primary">
              <a
                className="text-decoration-none text-white"
                href="https://www.auctionflex.com/af360register.htm"
              >
                Get A Free Trail
              </a>
            </button>
          </div>
        </div> */}
      </div>
    </div>
    </>
  );
};
export default AboutHibid;
